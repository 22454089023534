<template>
  <div class="header-section">
    <div :class="darkMode ? 'dark-first-header' : 'first-header'">
      <img class="logo" src="../assets/images/energy_ai_logo.png" alt="SWIFT Energy AI" />
      <div class="settings">
        <button @click="settingToggle" class="setting-button">
          <font-awesome-icon :icon="['fas', 'gear']" :class="darkMode?'dark-icon':'light-icon'" />
        </button>
        <transition name="setting">
          <div :class="['setting-menu', darkMode ? 'darkModeTheme' : '']" v-if="active">
            <span
              ><font-awesome-icon icon="fa-solid fa-user" class="fa-icon" /><span
                class="setting-menu-text"
                @click="toggleProfile"
                >Profile
              </span>
            </span>
            <!-- <span><font-awesome-icon icon="fa-solid fa-check" class="fa-icon" /><span
                class="setting-menu-text">Permissions</span></span>
            <span><font-awesome-icon :icon="['fas', 'users']" size="xs" class="fa-icon" /><span
                class="setting-menu-text">Groups</span></span> -->
            <a
              href="https://swiftlearning.com/contact-us/"
              target="_blank"
              rel="noopener noreferrer"
              class="support-link"
            >
              <span
                ><font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" class="fa-icon" /><span
                  class="setting-menu-text"
                >
                  Support</span
                ></span
              ></a
            >
            <span v-if="!darkMode"
              ><font-awesome-icon :icon="['fas', 'moon']" class="fa-icon" /><span
                class="setting-menu-text"
                @click="toggleDarkMode"
                >Dark Mode</span
              ></span
            >
            <span v-else
              ><font-awesome-icon :icon="['fas', 'sun']" class="fa-icon" /><span
                class="setting-menu-text"
                @click="toggleDarkMode"
                >Light Mode</span
              ></span
            >
            <span class="sign-out" @click="signUserOut"
              ><font-awesome-icon
                class="sign-out-icon fa-icon"
                :icon="['fas', 'sign-out-alt']"
              /><span class="setting-menu-text">Logout</span></span
            >
          </div>
        </transition>
      </div>
    </div>

    <div
      :class="darkMode ? 'dark-second-header' : 'second-header'"
      v-show="Object.keys(userTools).length > 1"
    >
      <!-- <div v-if="applicationActive" class="application-menu">
          <div class="application-menu-item" >
            <font-awesome-icon :icon="['fas', 'message']" class="menu-icon"/>
            <span class="menu-text">Cited Chat</span>
            </div>
          <div class="application-menu-item">
            <font-awesome-icon :icon="['fas', 'newspaper']" class="menu-icon"/>
            <span class="menu-text">News</span>
        </div>
        </div> -->
      <div class="second-navbar" v-for="(chatType, index) in userTools" :key="index">
        <!-- <div class="second-navbar"  > -->
        <div
          class="second-items menu-items"
          v-if="Object.keys(chatType.topics).length > 1"
          :class="{
            'highlight highlight-color':
              chatTypeSelected.chat_type_id == chatType.chat_type_id,
              'dark-lower-border': darkMode
          }"
          @click="selectChatType(index)"
        >
          {{ index }}
        </div>
        <div
          class="second-items menu-items"
          :class="{
            'highlight highlight-color':
              chatTypeSelected.chat_type_id == chatType.chat_type_id,
              'dark-lower-border': darkMode
          }"
          v-else
          @click="selectChatType(index)"
        >
          {{ index }} 
        </div>
        <!-- <div class="second-items menu-items">FreeChat</div> -->
        <!-- <div class="second-items menu-items">AI Assistants</div> -->
      </div>
    </div>

      <div v-if="showTopics" :class="darkMode ? 'dark-third-header' : 'third-header'">
  <div class="third-wrapper" 
  ref="thirdWrapper"
       @mouseenter="isHovered = true" 
       @mouseleave="isHovered = false" 
       @wheel="handleScroll"> <!-- Use @wheel for scroll events -->
       <!-- <font-awesome-icon v-if="showScrollArrows" :icon="['fas', 'circle-chevron-left']" @click="scrollLeft" class="scroll-arrow" :class="{ 'pulsing': isScrolling }"/> -->
    <div class="third-navbar scroll scroll-1" ref="topicWrapper"> <!-- Assign ref here -->
      <div v-for="(chatTopicItem, topic)  in chatTypeSelected.topics" :key="topic">

        <!-- {{ chatTypeSelected.topics[Object.keys(chatTypeSelected.topics.length-1] }} -->
        <!-- {{ Object.keys(chatTypeSelected.topics)[Object.keys(chatTypeSelected.topics).length-1] }} -->
          <!-- {{ topic }} -->
        <div class="third-items " 
             @click="selectChatTopic(chatTopicItem)"
             :class="{ 'third-highlight': chatTopicItem.topic_id == chatTopic.topic_id }">
          <span class="topic-wrapper" :class="{'dark-lower-border': darkMode}" :title="topic" >{{ topic }}</span>
          <div v-if="topic!=Object.keys(chatTypeSelected.topics)[Object.keys(chatTypeSelected.topics).length-1]"  :class="darkMode ? 'topic-divider-light' : 'topic-divider'"></div>
        </div>
        <!-- <font-awesome-icon
          :icon="['fas', 'circle-chevron-right']"
          @click="scrollRight"
          class="scroll-arrow"
          :class="{ pulsing: isScrolling }"
        /> -->
      </div>
    </div>
    <!-- <font-awesome-icon v-if="showScrollArrows" :icon="['fas', 'circle-chevron-right']" @click="scrollRight" class="scroll-arrow" :class="{ 'pulsing': isScrolling }"/> -->
  </div>
</div>

    <div v-if="profileActive">
      <Profile
        :profile-active="profileActive"
        :dialog-state="dialogState"
        @toggle-profile="toggleProfile"
        @profile-updated="showSuccessMessage"
      />
    </div>
    <Transition name="popup-animation">
      <div v-if="successMessage" class="popup">
        {{ successMessage }}
      </div>
    </Transition>
  </div>
</template>
  
  <script>
  import { mapState } from "vuex";
  import  Profile  from "./Profile.vue"
  import {closeableDirective} from '../composables/ClickOutside.js';
  
  export default {
    name: "Header",
    components: {
      Profile,
    }, 
    props:{
  
    },
    created(){
      if(this.userTools && Object.keys(this.userTools).length>0)
    {
      if(Object.keys(this.chatTypeSelected).length==0)
      {
        this.$store.commit('setChatTypeSelected',this.userTools[Object.keys(this.userTools)[0]])
      } 
      if(Object.keys(this.chatTopic).length==0)
      {
        this.selectChatTopic()
      }
    }
  },
  data() {
    return {
      active: false,
      applicationActive: false,
      thirdApplicationActive: false,
      profileActive: false,
      dialogState: false,
      successMessage: "",
      isHovered: false,
      isScrolling: false,
      scrollTimeout: null,
      // chatTypeSelected:{},
      // darkMode:false
    };
  },
  watch: {},
  directives: {
    closeable: closeableDirective,
  },
    computed: {
      ...mapState({
        userTools:(state)=>state.userTools,
        chatTypeSelected:(state)=>state.chatTypeSelected,
        chatTopic:(state)=>state.chatTopic,
        darkMode:(state)=>state.darkMode
      }),

      showTopics(){
        if( this.chatTypeSelected && 'topics' in this.chatTypeSelected)
        {
          return Object.keys(this.chatTypeSelected.topics).length>1
        }
        return false
      },
    },
    methods: {
      signUserOut(){
        this.$store.dispatch('logUserOut');
      },
      settingToggle() {
        this.active = !this.active

          //HANDLES OUTSIDE CLICKS FOR SETTING
        if (this.active) {
          document.addEventListener('click', this.handleOutsideClick)
        } else {
          document.removeEventListener('click', this.handleOutsideClick)
        }
      },
      handleOutsideClick(event) {
      const menu = this.$el.querySelector('.setting-menu');
      const button = this.$el.querySelector('.setting-button');

      if (menu && !menu.contains(event.target) && !button.contains(event.target)) {
        this.active = false;
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
    selectChatTopic(topic = null) {
      let topicSelected = {};

        if(topic!=null)
        { 
          topicSelected=topic;
        }
        else{
          topicSelected=this.chatTypeSelected.topics[Object.keys(this.chatTypeSelected.topics)[0]];//? we set the selected to be the first one 
        }
        this.$store.commit('updateChatTopic', topicSelected)
        this.$store.commit('updateSelectedTopicTool', this.chatTopic.user_tools[Object.keys(this.chatTopic.user_tools)[0]])
        // this.$emit('chatTopicSelected', topicSelected); // Emit selected value to parent component
      },
      selectChatType(itemSelected){
        this.$store.commit('setChatTypeSelected',this.userTools[itemSelected])
        this.selectChatTopic()
      },

    toggle() {
      this.applicationActive = !this.applicationActive;
    },
    darkToggle() {
      this.darkMode = !this.darkMode;
    },
    toggleDarkMode() {
      this.$store.dispatch("toggleDarkMode");
    },
    toggleProfile(newState) {
      this.dialogState = this.profileActive = newState;
      this.$emit("dialogState", this.dialogState);
    },

    showSuccessMessage(message) {
      this.successMessage = message;
      setTimeout(() => {
        this.successMessage = "";
      }, 3000);
    },

    handleScroll(event) {
      if (this.isHovered) {
        this.triggerPulse();
        // Prevent vertical scroll and only scroll horizontally
        event.preventDefault();
        this.$refs.topicWrapper.scrollLeft += event.deltaY * 5; // Corrected from scrollRight to scrollLeft
      }
    },

    scrollLeft() {
      const container = this.$refs.topicWrapper;
      container.scrollLeft -= 300; // Scroll left by 150 pixels
      this.triggerPulse();
    },

    scrollRight() {
      const container = this.$refs.topicWrapper;
      container.scrollLeft += 300; // Scroll right by 150 pixels
      this.triggerPulse();
    },

    triggerPulse() {
      // Set the flag to true and clear any existing timeout
      this.isScrolling = true;
      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);

      // Remove the pulsing class after 1 second of no scrolling
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
      }, 1000); // 1 second timeout
    }

    },
  };
  </script>
  
  <style  lang="scss"  >
  @use "../assets/styles/main.scss" as main;
  @use "./styles/Header.scss" ;
  </style>
  
