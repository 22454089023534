<template>
    <div class="chart-container">
      <component ref="chartComponent" :refreshTime="newDataLoadTime" :commonChartOptions="commonChartOptions" :additionalPlugins="additionalChartPlugins" @zoomPanChanged="isChartZoomed"  :is="chartTypeComponent" :chartInfo="chartInfo"/>
      <div class="button-container">
        <button v-if="chartZoomed"  @click="resetChart" v-tooltip="'Reset Position'" > <font-awesome-icon class="option-icon" :icon="['fas', 'undo']" /></button>
      </div>
    </div>
  </template>
  
  <script>
    import LineChart from './LineChart.vue';
    import BarChart from './BarChart.vue';
    import BubbleChart from './BubbleChart.vue';
    import PieChart from './PieChart.vue';
    import RadarChart from './RadarChart.vue';
    import DoughnutChart from './DoughnutChart.vue';
    import ModelRequest from '@/pages/ModelRequest.vue';
    import { mapState } from "vuex";
    import { plugin } from './TestChartData.ts';
    import {
    Chart as ChartJS
    } from 'chart.js'
    ChartJS.register(
        plugin
    )
    export default { 
    name:"ChartComponent",
    props: {
        chartInfo:Object
    },
    components: {
        LineChart,
        BarChart,
        BubbleChart,
        PieChart,
        RadarChart,
        DoughnutChart,
        ModelRequest
    },
    data() {
      return {
        chartZoomed:false,
        newDataLoadTime:1500,
        commonChartOptions:{
            grid:{
                color:'lightgrey',
                lineWidth:0.7
            }
        }
      };
    },
    mounted(){

        this.$nextTick(()=>{
            // this.isChartZoomed()
        })
    },
    computed: {
        chartTypeComponent(){
            switch(this.chartInfo.chartType.toLowerCase()){
                case 'line':
                    return LineChart
                case 'bar':
                    return BarChart
                case 'bubble':
                    return BubbleChart
                case 'pie':
                    return PieChart
                case 'radar':
                    return RadarChart
                case 'doughnut':
                    return DoughnutChart
                case 'input':
                    return ModelRequest
                default:
                    return LineChart
            }
        },
        additionalChartPlugins(){
            return {
                customCanvasBackgroundColor: {
                  darkMode: this.darkMode
                },
            }
        },
        ...mapState({
            darkMode: (state) => state.darkMode
      }),
    },
    watch: {
    darkMode(){
        // this.chartOptions.plugins.customCanvasBackgroundColor.darkMode = this.darkMode;
        if('chartOptions' in this.$refs.chartComponent && 'plugins' in this.$refs.chartComponent.chartOptions && 'customCanvasBackgroundColor' in this.$refs.chartComponent.chartOptions.plugins)
        {
            this.$refs.chartComponent.chartOptions.plugins.customCanvasBackgroundColor.darkMode = this.darkMode;
        }
        this.$nextTick(() => {

            this.$refs?.chartComponent?.$refs?.chartRef?.chart.update();
        });
    }

    },
    methods: {
        isChartZoomed(){
        if('chartComponent' in this.$refs && 'chartRef' in this.$refs.chartComponent.$refs && 'chart' in this.$refs.chartComponent.$refs.chartRef && this.$refs.chartComponent.$refs.chartRef.chart.isZoomedOrPanned()==true)
        // if('chartComponent' in this.$refs && 'chartRef' in this.$refs.chartComponent.$refs && 'chart' in this.$refs.chartComponent.$refs.chartRef )
            {
                this.chartZoomed=true;
            }
            else{
                this.chartZoomed=false;
            }
        },
     resetChart(){
        if('chartComponent' in this.$refs && 'chartRef' in this.$refs.chartComponent.$refs && 'chart' in this.$refs.chartComponent.$refs.chartRef)
        {
            this.$refs.chartComponent.$refs.chartRef.chart.resetZoom()
            this.isChartZoomed()
        }
     }
    }
  };
  </script>
  
  <style scoped lang="scss"  >
  @use "../../assets/styles/main.scss" ;
  .chart-container{
      width: 100%;
      height: 100%;
      max-height: 300px;
      position: relative;
      
  }
  .button-container{
    position: absolute;
    top:0;
    right: 0;
  }
  </style>
  