<template>
  <GDialog v-model="value" :width="w" :height="h"  ref="dialog" :background="'transparent'" content-class="profile" :depressed="true" >

    <!-- Profile content -->
    <div id="profile" :class="['profile', darkMode ? 'darkModeTheme' : '']" style="height: 90%">

      <div id="profile-top" class="profile-top">
        <span>Profile</span>
        <div @click="toggleProfile">
          <font-awesome-icon :icon="['fas', 'x']" class="profile-x" />
        </div>
      </div>

      <div class="profile-information scroll scroll-1">
        <!-- Loader -->
        <div v-if="loading" class="loader"></div>

        <!-- Profile Info -->
        <!-- Editing Form -->
        <div v-if="loading === false">
          <div class="editing-form">
            <div>
              <div><label for="first-name">First Name:</label></div>
              <div><input v-model="firstName" id="first-name" type="text"  /></div>
            </div>
            <div>
              <div><label for="last-name">Last Name:</label></div>
              <div><input v-model="lastName" id="last-name" type="text" /></div>
            </div>
            <div>
              <div><label for="email">Email:</label></div>
              <div><input v-model="email" id="email" type="email" /></div>
            </div>
            <div>
              <div><label for="password">Enter Password:</label></div>
              <transition name="password-tooltip">
                <div v-if="showPasswordError" class="password-tooltip">
                  <span>{{ passwordTooltip }}</span>
                  <font-awesome-icon
                    :icon="['fas', 'x']"
                    class="tooltip-close"
                    @click="showPasswordError = false"
                  />
                </div>
              </transition>
              <input
                v-model="password"
                id="password"
                type="password"
                placeholder="Required"
                :title="passwordTooltip"
                :class="{ 'invalid-password': !password && showPasswordError }"
              />
            </div>

            <div>
              <label for="new-password">New Password:</label>
              <transition name="password-tooltip">
                <div v-show="showNewPasswordToolTip" class="new-password-tooltip">
                  <!-- <span>{{ newPasswordTooltip }}</span> -->
                   <PasswordChecker :password="newPassword" :confirmPassword="confirmNewPassword" @passwordsValidUpdated="(updated)=>passwordsValid=updated" />
                  <font-awesome-icon
                    :icon="['fas', 'x']"
                    class="tooltip-close"
                    @click="closeTooltip"
                  />
                </div>
              </transition>
              <input
                v-model="newPassword"
                id="new-password"
                type="password"
                class="newPassword-input"
                placeholder="Optional"
                :title="newPasswordTooltip"
                :class="{ 'invalid-password': !isValidPassword && newPassword }"
              />
            </div>
            <div>
              <label for="confirm-new-password">Confirm New Password:</label>
              <!-- <transition name="confirm-new-password-tooltip">
                <div v-if="showTooltip" class="confirm-new-password-tooltip">
                  <span>{{ confirmNewPasswordTooltip }}</span>
                  <font-awesome-icon
                    :icon="['fas', 'x']"
                    class="tooltip-close"
                    @click="closeTooltip"
                  />
                </div>
              </transition> -->
              <input
                v-model="confirmNewPassword"
                id="confirm-new-password"
                type="password"
                class="confirmNewPassword-input"
                placeholder="Confirm new password"
                :title="confirmNewPasswordTooltip"
                :class="{ 'invalid-password': newPassword !== confirmNewPassword && showPasswordError }"
              />
            </div>
          </div>
          <div class="button-container">
            <button @click="saveProfile" :class="{ 'inactive-button': !canSave }" class="profile-edit-button">Save Changes</button>
          </div>
        </div>
      </div>


    <div v-if="successMessage" class="popup">
      {{ successMessage }}
    </div>
  </div>
  </GDialog>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { getUserProfile, updateUserProfile } from "@/util/apiCalls";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import global from '@/util/GlobalFunctions';
import PasswordChecker from '@/components/PasswordChecker.vue';
export default defineComponent({
  name: "Profile",
  components: {
    PasswordChecker
  },
  mixins: [global],
  data() {
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;
    let width = innerWidth;
    let height = 0.65 * innerHeight;

    if (innerWidth <= 1440 && innerWidth > 1024) {
      width = 0.4 * innerWidth;
    }
    else if ( innerWidth <= 1024 && innerWidth > 768) {
      width = 0.55 * innerWidth;
    }
    else if ( innerWidth <= 768 ) {
      width = 0.8 * innerWidth;
    }
    else {
      width = 0.35 * innerWidth;
    }

    return {
      w: width,
      h: height,
      'content-class': 'profile',
      isEditing: false,
      loading: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      newPassword: "",
      confirmNewPassword: "",
      successMessage: "",
      newPasswordTooltip:
        "Enter a new password with at least 6 characters, including at least one uppercase letter, one lowercase letter and one number or special character.",
      confirmNewPasswordTooltip: "Confirm your new password.",
      isValidPassword: true,
      shakeAnimation: false,
      showTooltip: false,
      showPasswordError: true,
      passwordTooltip: "Password is required.",
      passwordsValid:false,
    };
  },
  props: ['profileActive', 'dialogState'],
  mounted() {
    this.loadUserProfile();
  },
  computed:{
    ...mapState({
        darkMode:(state)=>state.darkMode,
      }),
      canSubmit(){
        return (this.firstName.length>0 && this.lastName.length>0 && this.email.length>0 && this.password.length>0 && this.passwordIsValid(this.password) && this.emailIsValid(this.email)
        && this.nameIsValid(this.firstName) && this.nameIsValid(this.lastName));
      },
      canSave() {
        if(this.newPassword.length>0 || this.confirmNewPassword.length>0){
          return this.canSubmit && (this.passwordsValid==true);
        }
        return this.canSubmit;
      },
      showNewPasswordToolTip(){
        if((this.newPassword.length>0 || this.confirmNewPassword.length>0) && this.passwordsValid==false){
          return true;
        }
        return false;
      }
  },
  methods: {
    //? if we want to only check when we are finished we can do this and put a blur trigger on it
    // checkPasswordValidity() {
    //   if(this.newPassword!="" && this.confirmNewPassword!="" && this.passwordsValid==false){
    //     this.showTooltip = true;
    //   }
    //   else{
    //     this.showTooltip = false;
    //   }
    // },
    async loadUserProfile() {
      try {
        const response = await getUserProfile();

        if (response.status === "success") {
          const userData = response.results;
          this.firstName = userData.first_name;
          this.lastName = userData.last_name;
          this.email = userData.email;
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },

    toggleProfile() {
      this.$emit("toggle-profile", !this.profileActive);
    },
    async saveProfile() {
      if (  this.password.length === 0 || this.password.value === '' ) {
        this.showPasswordError = true;
        this.triggerShakeAnimation();
      }
      else if ( this.newPassword.length > 0 &&  this.confirmNewPassword !== this.newPassword && ( this.confirmNewPassword.length === 0 ||
        this.confirmNewPassword.value === null || this.confirmNewPassword.value === '' ) ){
        this.showPasswordError = true;
      }
      else {
        this.showPasswordError = false;
        this.validatePassword();

        if (!this.isValidPassword && this.newPassword) {
          this.triggerShakeAnimation();
          this.showTooltip = true;
          return;
        }

        this.loading = true;

        const updatedProfile = {
          email: this.email, // This can be empty to skip email changes
          password: this.password, // Required
          first_name: this.firstName,
          last_name: this.lastName,
          new_password: this.newPassword || null, // Optional: only if the user wants to change password
        };

        try {
          await updateUserProfile(updatedProfile);
          this.loading = false;
          this.isEditing = false;
          this.toggleProfile();
          this.showSuccessMessage("Profile updated successfully!");
        } catch (error) {
          this.loading = false;
          console.error("Error updating profile", error);
        }
      }
    },
    showSuccessMessage(message) {
      this.successMessage = message;
      setTimeout(() => {
        this.successMessage = "";
      }, 3000);
    },

    validatePassword() {
      this.isValidPassword=this.passwordIsValid(this.newPassword);
      // const lowercaseRegex = /[a-z]/;
      // const uppercaseRegex = /[A-Z]/;
      // // const specialCharacterRegex = /[!@#$%^&*()_+{}:;<>,.?]/;
      // const specialCharacterRegex = /[!@#$%^&*()_+{}:;<>,.?0-9]/;

      // this.isValidPassword =
      //   this.newPassword.length >= 6 &&
      //   lowercaseRegex.test(this.newPassword) &&
      //   uppercaseRegex.test(this.newPassword) &&
      //   specialCharacterRegex.test(this.newPassword);
    },

    closeTooltip() {
      this.showTooltip = false;
    },

    triggerInvalidPasswordEffect() {
      this.showTooltip = true;
    },

    triggerShakeAnimation() {
      // const profileDiv = this.$el.querySelector(".profile");
      // profileDiv.classList.add("shake");
      //
      // setTimeout(() => {
      //   profileDiv.classList.remove("shake");
      // }, 500);
    },
  },
  setup(props, { emit }) {
    const value = useVModel(props, 'dialogState', emit)

    const onClose = () => {
      value.value = false
    }

    return {
      value,
      onClose,
    }
  },
  watch: {}
});
</script>

<style scoped lang="scss"  >
@use "../assets/styles/main.scss"  ;
@use "./styles/Profile.scss" ;
</style>
