<template>
    <div @click="checkClose($event)"  v-closeable="{exclude:excludedElements,handler:'closeMenu'}" class="option-menu">
        <div>
          <ul>
            <li @click="deleteRecord">Delete</li>
          </ul>
            </div>
        </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  export default {
    name: "OptionMenu",
    components: {
    },
    props:{
        requestData:Object,
    },

    data() {
      return {

      };
    },
    watch: {
      requestData()
      {
      }
    },

    computed: {
      ...mapState({
        currentMenuID: state => state.currentMenuID
      }),
      excludedElements(){
        return ['option-menu']
      },
    },
    methods: {
      deleteRecord(){
        this.$emit('deletingRecord')
        this.closeMenu()
        this.$store.dispatch('deleteRecordSession',this.requestData.chat_session_id).then((res)=>{
          res
        }).catch((err)=>{
          console.error('Error: could not delete record with id',this.requestData.chat_session_id)
          console.error(err)
        })
      },
      checkClose(event){
        event.stopPropagation() //? this seems to stop it even more. It will not even go below or anything 
        event.preventDefault() //? this is needed. Otherwise it would have issues with the v-closeable
      },
        closeMenu(){
          this.$store.commit('setCurrentMenuID',null)
        }
    },
  };
  </script>
  
  <style  lang="scss"  >
  @use "../assets/styles/main.scss"  ;
  .option-menu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    width: 150px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: Arial, sans-serif;
    z-index: 10000;
    border-radius: 6px;
    border: 1px solid black;
  }
  
  .option-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .option-menu li {
    color: black;
    padding: 10px;
    cursor: pointer;
  }
  
  .option-menu li:hover {
    background-color: #f0f0f0;
  }
  
  .option-menu li:active {
    background-color: #ccc;
  }
  </style>
  