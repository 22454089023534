<template>
    <div class="radar-container">
      <Radar :data="displayChartData" ref="chartRef" class="radar-chart" :options="chartOptions"/>
    </div>
  </template>
  
  <script>
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'
import { Radar } from 'vue-chartjs'
import {vibrationData } from  './TestChartData.ts';
import _ from 'lodash';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)
  export default { 
    name:"RadarChart",
    components: {
      Radar  
    },
    props: {
        chartInfo:Object,
        refreshTime:Number,
        additionalPlugins:Object,
        commonChartOptions:Object
    },
    data() {
      return {
        medianData:[0.5480,0.4995,0.4120,0.5030],
        highData:[0.79400,0.68800,0.65900,0.82875],
        veryHighData:[1.0763,0.8786,0.9583,1.1119],
        borderColor:'5,5,5',
        pointColours:[],
        radarPosition:0,
        chartData:{
            labels: [
                'Drive End Y Direction',
                'Drive End X Direction',
                'Non Drive End Y Direction', 
                'Non Drive End X Direction'
            ],
            datasets: [
                {
                label: 'Vibrations',
                // backgroundColor: 'rgba(255,99,132,0.2)',
                backgroundColor: ()=>{
                  let colour=this.findBackgroundColor()
                  return `rgba(${colour}, 0.3)`

                },
                // borderColor: 'rgba(255,99,132,1)',
                borderColor:()=>{
                  return `rgba(${this.borderColor},1)`
                  // return 'rgba(255,99,132,1)'
                },

                // pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: (context)=>{ 
                  return this.getColour(context.dataIndex,context.raw);
                },
                pointBorderColor: 'grey',
                pointBorderWidth: 1,
                // pointBorderColor: (context)=>{
                //   return this.getColour(context.dataIndex,context.raw);
                // },
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                data: [65, 59, 90, 81]
                },
                // {
                // label: 'My Second dataset',
                // backgroundColor: 'rgba(255,99,132,0.2)',
                // borderColor: 'rgba(255,99,132,1)',
                // pointBackgroundColor: 'rgba(255,99,132,1)',
                // pointBorderColor: '#fff',
                // pointHoverBackgroundColor: '#fff',
                // pointHoverBorderColor: 'rgba(255,99,132,1)',
                // data: [28, 48, 40, 19, 96, 27, 100]
                // }
            ]
            },
        chartOptions:{
            responsive: true,
            maintainAspectRatio: false,
            plugins:{
              ...this.additionalPlugins,
              legend:{
                display:false,
              },
              title:{
                display:this.chartInfo?.name ? true:false,
                text:this.chartInfo.name,
              },
            },
            scales:{
              r:{
                angleLines:{
                  display:true
                },
                suggestedMin: 0,
                suggestedMax: 1,
                ticks:{
                  stepSize:0.2
                },
                grid:this.commonChartOptions?.grid??{}
              }
            },
        }
      };
    },
    mounted(){
      this.radarPosition=0;
      const intervalId = setInterval(() => {
        // this.chartData.datasets[0].data=[this.vibrationsData[this.radarPosition].DEX,this.vibrationsData[this.radarPosition].DEY, this.vibrationsData[this.radarPosition].NDEX,this.vibrationsData[this.radarPosition].NDEY]
        this.radarPosition++;
        if (this.radarPosition >= this.vibrationsData.length) {
          clearInterval(intervalId);
        }
      }, this.refreshTime); // 1000ms = 1s
    },
    computed: {
      vibrationsData(){
        return vibrationData;
      },
      displayChartData(){
        let tempData=_.cloneDeep(this.chartData)
        tempData.datasets[0].data=[this.vibrationsData[this.radarPosition]?.DEY,this.vibrationsData[this.radarPosition]?.DEX, this.vibrationsData[this.radarPosition]?.NDEY,this.vibrationsData[this.radarPosition]?.NDEX]
      return tempData;
      }
    },
    watch: {
     
    },
    methods: {
      getColorArr(index,value){
        let colors=[255,255,255]
        if(value<=this.highData[index]){
        colors=[3,252,23]
      }
      else if(value>this.highData[index] && value<=this.veryHighData[index]){
        colors=[252,211,3]
     }
     else if(value>this.veryHighData[index]){
      colors=[252,3,3]
    }
    return colors
      },
     getColour(index,value){

     let colors=this.getColorArr(index,value) 
      return `rgba(${colors[0]}, ${colors[1]}, ${colors[2]}, 1)`
    },
    findBackgroundColor(){
      let colorsArr=[]
      for(let i=0;i<this.displayChartData.datasets[0].data.length;i++){
        colorsArr.push(this.getColorArr(i,this.displayChartData.datasets[0].data[i]))
      }
      const averages = colorsArr[0].map((_, i) => {
      const sum = colorsArr.reduce((acc, arr) => acc + arr[i], 0);
      return Math.round(sum / colorsArr.length);
    });
    this.borderColor=averages.join(', ')
    return averages.join(', ')
    }
  }
  };
  </script>
  
  <style  lang="scss"  >
  @use "../../assets/styles/main.scss" ;
  .radar-container{
    height:100%;
  }
  .radar-chart{
    width: 100%;
    height:100%;
  }
  </style>
  