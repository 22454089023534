<template>
    <div class="historic-section">
        <div class="grouping-header" :class="darkMode ? 'dark-text':''">
            {{ groupedRecordsData.name }}
        </div>
        <div :class="{
        'record-scroll': !darkMode,
        'dark-record-scroll': darkMode,
        'expanded-scroll': expandedIndex === index && !darkMode,
        'dark-expanded-scroll': expandedIndex === index && darkMode
      }"
        v-for="(request, index) in groupedRecordsData.records" :key="index">
          <historic-request-entry
          :requestData="request"
          :selected="historicSelected"
          @selectRequest="recordSessionSelected"
          @expand=" () => handleExpand(index)"/>
        </div>
  </div>
  <!-- :class=" ? true class : false classs" -->
  
  
  </template>
  
    <script>
    import { mapState } from "vuex";
  import HistoricRequestEntry from './HistoricRequestEntry.vue';
  
    export default {
      name: "HistoricRequestGrouping",
      components: {
        HistoricRequestEntry,
      },
      emits: ["historicRecordChosen","historicRecordSelected","expandItem"],
      props:{
        groupedRecordsData:Object,
        name:String,
        expandedIndex:Number
      },
      created(){

      },
      mounted(){

      },
      data() {
        return {
        };
      },
      watch: {

      },
      computed: {
        ...mapState({
          historicSelected:(state)=>state.historicSelected,
          darkMode:(state)=>state.darkMode
        }),
      },
      methods: {
        recordSessionSelected(requestSelected){
          this.$emit('historicRecordSelected',requestSelected);
        },
        reset(){
          this.$store.dispatch('resetState');
        },
          handleExpand(index) {
            this.$emit('expandItem', index);
      },
  
      },
    };
    </script>
  
    <style  lang="scss"  >
    @use "../assets/styles/main.scss" ;
    @use "./styles/HistoricalRequests.scss" ;
    </style>
  