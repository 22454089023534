<template>
    <div class="bar-container">
      <Bar :data="barChartData" :options="chartOptions" ref="chartRef" class="bar-chart"/>
    </div>
  </template>
  
  <script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import _ from 'lodash';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  export default { 
    name:"BarChart",
    components: {
      Bar  
    },
    props: {
        chartInfo:Object,
        refreshTime:Number,
        additionalPlugins:Object,
        commonChartOptions:Object
    },
    data() {
      return {
        loaded:10,
        chartOptions:{
            responsive: true,
            maintainAspectRatio: false,
        scales: {
          y: {
            stacked:this.chartInfo.stacked??false,
              title:{
                display:this.chartInfo?.labels?.y ? true:false,
                text:this.chartInfo.labels?.y??""
              },
              grid:this.commonChartOptions?.grid??{},
            },
            x:{
              stacked:this.chartInfo.stacked??false,
              ticks:{
              },
              title:{
                display:this.chartInfo?.labels?.x ? true:false,
                text:this.chartInfo.labels?.x??""
              },
              grid:this.commonChartOptions?.grid??{},
            }
      
        },
            indexAxis: 'x',
            plugins:{
              ...this.additionalPlugins,
              legend:{
                display:true,
                labels:{
                  // color: 'rgb(255,99,132)',
                  font:{
                    size:11
                  }
                }
              },
              title:{
                display:this.chartInfo?.name ? true:false,
                text:this.chartInfo.name
              },
              zoom:{
                pan:{
                  enabled: true,
                  mode:'x',
                  onPan:()=>{
                    this.$emit('zoomPanChanged')
                  }
                },
                zoom:{
                  wheel:{
                    enabled: true
                  },
                  pinch:{
                    enabled: true
                  },
                  mode: 'xy',
                  onZoom:()=>{
                    this.$emit('zoomPanChanged')
                  },
   
                }
              }
            },

        }
      };
    },
    mounted(){
      const intervalId = setInterval(() => {
        // this.chartData.datasets[0].data=[this.vibrationsData[this.radarPosition].DEX,this.vibrationsData[this.radarPosition].DEY, this.vibrationsData[this.radarPosition].NDEX,this.vibrationsData[this.radarPosition].NDEY]
        this.loaded++;
        if (this.loaded >= this.chartInfo.data.labels?.length) {
          clearInterval(intervalId);
        }
      }, this.refreshTime); // 1000ms = 1s
    },
    computed: {
      barChartData(){
        let tempData=_.cloneDeep(this.chartInfo.data)
      tempData.labels=tempData.labels?.slice(0,this.loaded)
      for (var dataSet of tempData.datasets){
        let dataSetData=[...dataSet.data].slice(0,this.loaded)
        dataSet.data=dataSetData
      }
      return tempData;
      }
    },
    watch: {
     
    },
    methods: {
      
    }
  };
  </script>
  
  <style  lang="scss"  >
  @use "../../assets/styles/main.scss" ;
  .bar-container{
      width: 100%;
      height:100%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .bar-chart{
    width: 100%;
    height: 100%;
  }
  </style>
  