<template>
  <div class="grid-container">
    <div class="chart-grid scroll scroll-1">
     <div v-for="chart in chartList" :key="chart.id" class="chart-item" :class="{'span-two': ('cols' in chart && chart.cols==2)}">
      <ChartComponent :ref="chart.id" :chartInfo="chart" />
    </div>
    </div>
    </div>
      
    </template>
    
    <script>
    import { mapState } from "vuex";
    import ChartComponent from '../components/charts/ChartComponent.vue';
    import { windingTestData, pressureData, allTempData, outputData } from "@/components/charts/TestChartData.ts";

    export default {
      name: "ChartsPage",
      components: { 
        ChartComponent
      },
      props:{
          responseData:Object
      },
      data() {
        return {
          //? The name is the title, the chart type is that, the cols is the width, the labels are only if specified and the zoom is only if specified and false
          chartList:[
            {id:1,name:'MP-VC-07 Winding Temperatures',chartType:'line',data:windingTestData,cols:2,labels:{x:'Time',y:'Temperature'},zoom:true},
            {id:2,name:'MP-VC-07 Pressures',chartType:'bar',data:pressureData, stacked:true,labels:{x:'Time',y:'Pressure'},cols:1},
            {id:3,name:'MP-VC-07 Vibrations',chartType:'radar',cols:1,data:[]},
            {id:4,name:'Chat Input',chartType:'input',cols:2},
            {id:5,name:'MP-VC-07 Temperatures',chartType:'line',data:allTempData,stacked:false,labels:{x:'Time',y:'Temperature'},cols:2},
            {id:6,name:'MP-VC-07 Output',chartType:'line',data:outputData,stacked:false,labels:{x:'Time',y:'Flow Rate'},cols:1},
            // {id:7,name:'',chartType:'bubble',data:[],cols:1,labels:{x:'Time',y:'Temperature'}},
            // {id:8,name:'',chartType:'Doughnut',cols:1,data:[]}
        ]
        };
      },
  
      mounted(){
      },
      computed: {
        ...mapState({
          darkMode:(state)=>state.darkMode
        }),
      },
      watch:{
  
      },
      methods: {
        historicRecordChosen(record)//? this is used because the main page will check if its refs have this and if so pass the history. We want to take it and pass it to the input one if we have it 
        {
          for (const ref in this.$refs) {
          const chartRef = this.$refs[ref]?.[0];
          
          if (chartRef?.chartInfo?.chartType === 'input') {
            const chartComponent = chartRef.$refs?.chartComponent;
            
            if (chartComponent?.historicRecordChosen) {
              chartComponent.historicRecordChosen(record);
            }
          }
        }
        }
      },
    };
    </script>
    
    <style  lang="scss"  >
      @use "./styles/ChartsPage.scss" ;
      @use "../assets/styles/main.scss" ;
    </style>
    