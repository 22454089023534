<template>
    <div class="test-input-field" :class="[includeSubmitButton?darkMode?'test-input-field-no-button-dark':'test-input-field-no-button':'',]">
      <!-- <textarea v-if="!('required' in field && field['required'] && field_name=='user_query') && 'type' in field && field['type']=='str' " v-on:keydown.enter="checkKeyPressSubmit" ref="projectQuery" spellcheck="true" :class="[ darkMode ? 'dark-query-input' : 'query-input', 'project-input']" v-model="text_input" :placeholder="field_name.charAt(0).toUpperCase() + field_name.slice(1)" required></textarea> -->
       <!-- {{field_name}}:{{field}} -->
      <textarea v-if=" 'type' in field && field['type']=='str' "
       @input="updateThings"
        v-on:keydown.enter="checkKeyPressSubmit" 
        ref="projectQuery" 
        spellcheck="true"
         :class="[ darkMode ? 'dark-query-input' : 'query-input', 'project-input',includeSubmitButton?'with-submit-button':'']" 
         v-model="text_input" 
         :placeholder="field.placeholder||'Chat With SWIFT AI'" >
        </textarea>
        <div v-if="includeSubmitButton" class="submit-icon"  @click="sendRequest" v-tooltip.top="'Send'"
                     v-bind:class="{'disabled-icon':sendIconDisabled, 'dark-send-icon': darkMode}">
                  <font-awesome-icon class="send-icon" :icon="['fas','location-arrow']"
                                     :style="{ color: darkMode ? '#ffffff' : '#0e59f6' }" size="2xl" />
        </div> 
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  // import ButtonFormField from "./ButtonFormField.vue";
//   import Spinner_2 from "./Spinner_2.vue";
  export default {
    name: "TextFormField",
    components: {
    //   Spinner_2,
    // ButtonFormField,
    },
    props:{
        field:Object,
        field_name:String,
        darkMode:Boolean,
        includeSubmitButton:Boolean,
    },

    data() {
      return {
        text_input:null,
        showSubmitButton:true,
        sendIconDisabled:false
      };
    },
    mounted(){
      this.showSubmitButton=this.includeSubmitButton
    },
    watch:{
        field:{ //? this is only really used if the field is changed and the text is not 
            handler(){
              this.$nextTick (()=>{
                this.checkResize()
              })
                if('value' in this.field && this.field.value != this.text_input){
                    this.text_input=this.field.value
                }
            },
            deep:true
        }
    },

    computed: {
      ...mapState({
      }),
    },
    methods: {
        updateThings(){
            this.$emit('updateField',this.text_input)
        },
        checkKeyPressSubmit(e){
          this.$emit('checkKeyPress',e)
          },
          sendRequest(){
            this.$emit('submitted')
          },
        checkResize(project=false){
          project
        // const textarea = project?this.$refs.projectQuery: this.$refs.textAreaQuery;
        const textarea = this.$refs.projectQuery;
        textarea.style.height = `auto`; // Set the height to the scrollHeight
        if(textarea.scrollHeight>textarea.clientHeight)
        {
          textarea.style.height = `${Math.min(textarea.scrollHeight - 10, 250)}px`;
          // textarea.style.height = `${Math.min(textarea.scrollHeight-10,'100%')}`; // Set the height to the scrollHeight
        }
      },
    },

  };
  </script>
  
  <style scoped lang="scss"  >
  @use "../../assets/styles/main.scss" ;

  .test-input-field-no-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: solid black 1px;
    border-radius: 0.5rem;
  }
  .test-input-field-no-button-dark{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #555555;
    width: 100%;
    border: solid black 1px;
    border-radius: 0.5rem;
  }
  .with-submit-button{
    outline: none;
    box-shadow: none;
    border: none;
  }
  .with-submit-button-dark{
    outline: none;
    box-shadow: none;
    border: none;
  }
  .submit-icon{
    background-color: transparent;
  }
  .submit-icon:hover{
    background-color: transparent;
    cursor: pointer;
  }
  </style>
  