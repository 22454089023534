<template>
        <div class="password-list">
          <span class="password-list-title">Passwords must contain:</span>
          <ul class="condition-list">
            <li :class="{ valid: isLongEnough }">At least 6 characters</li> 
            <li :class="{ valid: hasUppercase }">At least 1 uppercase letter</li>
            <li :class="{ valid: hasLowercase }">At least 1 lowercase letter</li>
            <li :class="{ valid: hasSpecialCharacter }">At least 1 number or special character</li>
            <li :class="{ valid: passwordsMatch }">Passwords must match</li>
          </ul>
        </div>
     
  </template>
  
  <script>
  import {mapState} from "vuex";

  export default {
    name: "PasswordChecker",
    mixins: [global],
    components: {
    },
    data() {
      return {
        lowercaseRegex: /[a-z]/,
        uppercaseRegex: /[A-Z]/,
        specialCharacterRegex: /[!@#$%^&*()_+{}:;<>,.?0-9]/,
      };
    },
    props:{
        password:String,
        confirmPassword:String,
    },
    computed: {
      ...mapState({
        darkMode: state => state.darkMode
      }),
      isLongEnough() {
        return this.password.length >= 6;
      },
      hasUppercase() {
        return this.uppercaseRegex.test(this.password);
      },
      hasLowercase() {
        return this.lowercaseRegex.test(this.password);
      },
      hasSpecialCharacter(){
        return this.specialCharacterRegex.test(this.password);
      },
      passwordsMatch(){
        if(this.confirmPassword.length>=6 && this.password.length>=6){
            return this.password===this.confirmPassword
        }
        return false
      },
      passwordsValid(){
        return this.isLongEnough && this.hasUppercase && this.hasLowercase && this.hasSpecialCharacter && this.passwordsMatch;
      }
    },
    watch:{
        passwordsValid(){
            this.$emit('passwordsValidUpdated',this.passwordsValid)
        }
    },
    mounted () {
    },
    methods: {

    },
  };
  </script>
  
  <style scoped lang="scss">
  @use "@/assets/styles/main.scss" ;
  .valid{
    color: green;
  }
  .password-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  .condition-list{
    margin:0;
    padding-top:5px;
    padding-left: 30px;
    font-size: 14px;
  }
  .password-list-title{
    padding-left:10px;
  }
  </style>
  