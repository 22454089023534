<template>
  <div  class="custom-node-task-container" :class="highlightedClass" @dblclick="doubleClicked" @mouseenter="highlightCheck=true" @mouseleave="highlightCheck=false">
    <div class="task-container" >
             <!-- ? nodrag can be added as a class if we do not want things to drag. in the example they have something inside which will not drag but make the outside draggable -->
            <div :contenteditable="editing" @blur="onblur" @input="updateTaskText" @focus="onfocus" :class="{'editing-text nodrag':editing}" class="task-text">{{taskText}}</div>
        </div>
        <div>
        </div>
    <Handle id="in" class="hidden-handle" v-if="horizontal!=null" type="target" :position="targetPosition" />
    <Handle id="out" type="source" v-if="horizontal!=null" :position="sourcePosition" />
    
    </div> 
    </template>
        
        <script>
        import { Handle, Position } from '@vue-flow/core'
        export default {
          name: "CustomTaskNode",
          // props:defineProps<EdgeProps>(),
        components:{
            Handle,
        },
        watch:{
          selectedNode(){ //? when the selected node changes we want to see if another one is editing. This will make it stop editing if another is selected
            if( this.editing==true && this.selectedNode!=this.nodeData.id){
              this.editing=false;
            }
          }
        },
        computed:{
          sourcePosition(){
            return !this.horizontal ? Position.Right : Position.Bottom; 
          },
          targetPosition(){
            return !this.horizontal ? Position.Left : Position.Top;
          },
          highlightedClass(){
            if(this.connecting==='true' && this.targetNodes.includes(this.nodeData.id))
            {
              return 'task-highlighted';
            }
            if( this.connecting!='true' && this.highlightCheck==true && this.connecting!=null && this.connecting!=this.nodeData.id)
            {
              if(this.targetNodes.includes(this.nodeData.id))
              {
                return 'task-highlighted-red';
              }
              return 'task-highlighted';
            }
            return '';
          }
        },
          props:{
            nodeData:Object,
            horizontal:Boolean,
            connecting:String,
            targetNodes:Array,
            selectedNode:String,
          },
          mounted(){
            if (this.nodeData.data.task) {
                this.taskText = this.nodeData.data.task;
            }
          },
          data() {
            return {
              highlightCheck:false,
              editing:false,
              taskText:"",
              focussed:false,
            };
          },
      
          methods: {  
            updateTaskText(event){
              this.taskText=event.target.textContent
            },
            doubleClicked(event){
              this.setEditing(!this.editing);
              if(this.editing==true)//? this is to have the cursor actually appear in the text area to edit it 
              {
                this.$nextTick(()=>{event.target.focus()})
                // event.target.focus();
              }
            },
            setEditing(editing){
            this.editing=editing
            if(this.editing==true)
            {
              this.$emit('editNodeUpdated',this.nodeData.id);
            }
            else{
              this.$emit('editNodeUpdated',null);
            }
            },
            onblur(){
                // this.editing=false;
                // this.setEditing(false);
                if(this.taskText!==this.nodeData.data.task)
                {
                  this.$emit('nodeContentUpdated',{nodeID:this.nodeData.id,nodeData:{task:this.taskText}});
                }
                this.focussed=false;
            },
            onfocus(event){
                if (event.target.isContentEditable) {
                    this.focussed = true;
                    }                
            }
          },
      
        };
        </script>
        
        <style scoped lang="scss"  >
        .custom-node-task-container{
            height:100%;
            width:100%;
        }
        html, body {
            height: 100%;
            margin: 0;
          }
        .task-container
        {
          border: solid black 1px;
          max-width: 200px;
          min-width: 100px;
          width: 100%;
          display: flex;
          align-items: stretch;
          flex-grow: 1;
          height:100%;
          margin: 0;
          padding: 0;
          pointer-events: all !important;
          user-select: all !important;
        }
        .task-text{
            width: 100%;
            height: 100%;
            min-height: 17px;
            border: none;
            padding:5px;
            overflow-y:visible;
            flex-grow: 1;
            resize: none;
            z-index: 1;
            pointer-events: all !important; /* Ensure contenteditable div can be interacted with */
            user-select: all !important; /* Allow text selection */
          }
          .editing-text{
            cursor: text !important;
            user-select: text !important;
          }
          .hidden-handle
          {
            background-color: transparent;
            border-radius: 0;
            left: 0;
            top:0;
            padding: 0;
            margin:0;
            transform: translate(0,0);
            height: 100%;
            width: 100%;
            z-index: -1;
            pointer-events: none;
          }
          .task-highlighted
          {
            border: solid blue 1px;
            margin: 0;
            padding: 0;
          }
          .task-highlighted-red
          {
            border: solid red 1px;
          }
          .task-text[contenteditable="false"] {
            pointer-events: auto; /* Ensure contenteditable div can be interacted with */
            /*user-select: text;*/ /* Allow text selection */
            user-select: none !important;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
              -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
          }
        </style>
        