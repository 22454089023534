<template>
  <div id="ModelRequest">
    <!-- <div id="sideBar"> -->
    <div :class=" darkMode ? 'dark-body-section' : 'body-section'">
      <!-- </div> -->
      <div :class="darkMode ? 'dark-mainSection' : 'mainSection'">
        <!-- <span style="color:white;">{{chartInfo}}</span> -->
        <div class="tool-container">
          <button class="tool-button"
            @click="Object.keys(chatTopic.user_tools).length > 1 ? toolsActive = !toolsActive : null"
            :class="applyGreyedOutClass()"
             ref="toolButton"
            >
            <font-awesome-icon :icon="['fas', 'wrench']" size="2xl" v-tooltip="'Tools'" :class="darkMode ? 'dark-icon' : 'light-icon'" />
          </button>
        
          <Transition name="tool-item">

            <div class="tool-menu" v-if="toolsActive"
              v-show="chatTopic && 'user_tools' in chatTopic && Object.keys(chatTopic.user_tools).length > 1"
              v-closeable="{ handler: 'closeToolMenu', exclude: ['toolButton'] }"
              >
              <div class="tool-item-wrapper" v-for="(user_tool, tool) in chatTopic.user_tools" :key="tool">
                <div
                  :class="{ 'tool-item': true, 'selected-tool': user_tool.topic_tool_id === selectedTopicTool.topic_tool_id }"
                  @click="selectUserTool(tool)">
                  {{ tool }}
                </div>
              </div>

            </div>
          </Transition>
        </div>



        <div id="mainContent" ref="pageContent" @scroll="checkShowDown" class="scroll scroll-1">


          <div class="conversation" id="conversation" ref="conversation">
            <div v-for="(conversationPiece,index) in modelConversation" :key="index">
              <ConversationEntry :conversationObject="conversationPiece" />
            </div>
            <div v-if="loading" class="loading-spinner">
              <Spinner_2 />
            </div>
            <div ref='after'></div>

        
          </div>
          <div :class=" darkMode ? 'dark-enter-info' : 'enter-info'"
            v-if=" modelConversation && modelConversation.length==0 && !loading">
            <div id="inputArea" class="inputArea" v-if=" modelConversation && modelConversation.length==0 && !loading">
              <div class="input-item" v-for="(field,field_name) in this.selectedTopicTool.tool_fields" :key="field_name"
                   :class="{'max-width-input': shouldTakeFullWidth(field)}">
                <component @submitted="sendRequest" @updateField="(value)=>updateField(value,field)" @checkKeyPress="checkKeyPressSubmit"
                           v-tooltip.top="field.tooltip" :is="getFieldComponent(field)" :ref="field_name" :darkMode="darkMode" :includeSubmitButton=" !showSubmitButton "
                           :field="field" :field_name="field_name" />
                <!-- <DocumentUploader v-if="'type' in field && field['type']=='document'" /> -->
                <!-- <textarea v-if="!('required' in field && field['required'] && field_name=='user_query') && 'type' in field && field['type']=='str' " v-on:keydown.enter="checkKeyPressSubmit" ref="projectQuery" spellcheck="true" :class="[ darkMode ? 'dark-query-input' : 'query-input', 'project-input']" v-model="requestModel[field_name]" :placeholder="field_name.charAt(0).toUpperCase() + field_name.slice(1)" required></textarea> -->
                <!-- <div class="text-submit" v-if="'required' in field && field['required'] && field_name=='user_query'" >
          <textarea v-on:keydown.enter="checkKeyPressSubmit" ref="textAreaQuery" spellcheck="true"   :class="darkMode ? 'dark-query-input' : 'query-input'" v-model="requestModel[field_name]" placeholder="Chat With Swift" required></textarea>
          <div class="submit-icon" @click="sendRequest" v-tooltip.top="'Send'" v-bind:class="{'disabled-icon':sendIconDisabled, 'dark-send-icon': darkMode}" >
            <font-awesome-icon class="send-icon"  :icon="['fas','location-arrow']" :style="{ color: darkMode ? '#ffffff' : '#0e59f6' }" size="2xl" />
          </div>
          </div> -->
              </div>
              <div v-if="!itemsContainButton && showSubmitButton" class="button-item">
                <div class="submit-icon" style="width:7em;" @click="sendRequest" v-tooltip.top="'Send'"
                     v-bind:class="{'disabled-icon':sendIconDisabled, 'dark-send-icon': darkMode}">
                  <font-awesome-icon class="send-icon" :icon="['fas','location-arrow']"
                                     :style="{ color: darkMode ? '#ffffff' : '#0e59f6' }" size="2xl" />
                </div>
              </div>
            </div>
          </div>


          <div class="down-arrow" v-tooltip.top="'Scroll Down'" v-if="showDownArrow" @click="goToListBottom">
            <font-awesome-icon :icon="['fas', 'chevron-down']" />
          </div>
        </div>

        <div id="inputAreaB" class="inputArea" v-if=" modelConversation && modelConversation.length > 0">
          <div class="input-item" v-for="(field,field_name) in this.selectedTopicTool.tool_fields" :key="field_name"
            :class="{'max-width-input': shouldTakeFullWidth(field)}">
            <component @submitted="sendRequest" @updateField="(value)=>updateField(value,field)" @checkKeyPress="checkKeyPressSubmit"
              v-tooltip.top="field.tooltip" :is="getFieldComponent(field)" :ref="field_name" :darkMode="darkMode" :includeSubmitButton="!showSubmitButton"
              :field="field" :field_name="field_name" />
          </div>
          <div v-if="!itemsContainButton && showSubmitButton" class="button-item">
            <div class="submit-icon" style="width:7em;" @click="sendRequest" v-tooltip.top="'Send'"
              v-bind:class="{'disabled-icon':sendIconDisabled, 'dark-send-icon': darkMode}">
              <font-awesome-icon class="send-icon" :icon="['fas','location-arrow']"
                :style="{ color: darkMode ? '#ffffff' : '#0e59f6' }" size="2xl" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template> 
  <script>
  import {mapState, mapMutations} from 'vuex';
import Spinner_2 from "../components/Spinner_2.vue";
// import CustomDropdown from '../components/CustomDropdown.vue';      
// import UserRequest from '../components/UserRequest.vue';
// import ModelResponse from '../components/ModelResponse.vue';  
import ConversationEntry from '../components/ConversationEntry.vue';
import DocumentUploader from '@/components/DocumentUploader.vue';
import TextFormField from '@/components/form_fields/TextFormField.vue';
import ButtonFormField from '@/components/form_fields/ButtonFormField.vue';
import CheckboxFormField from '@/components/form_fields/CheckboxFormField.vue';
import ModelOptionsFormField from '@/components/form_fields/ModelOptionsFormField.vue';
import { closeableDirective } from '@/composables/ClickOutside';

  export default { 
    name: "ModelRequest",
    components: {
      Spinner_2,
      // CustomDropdown,
      // UserRequest,
      // ModelResponse,
      DocumentUploader,
      ConversationEntry,
      TextFormField,
      CheckboxFormField,
      ButtonFormField,
      ModelOptionsFormField
      
    },
    data() {
      return {
        project:"",
        information:"",
        loading:false,
        responseText:"",
        largeChecked:false,
        selectedRequestOption:{},
        showDownArrow:false,
        maxWidthComponents:['str'],
        toolsActive: false
      };
    }, 
    props:{
      chartInfo:Object,
    },
    mounted(){
        this.checkResize();
        if(!this.selectedTopicTool )
        {
          return; 
        }
        if(Object.keys(this.selectedTopicTool).length==0)
        {
          this.$store.commit('updateSelectedTopicTool', this.chatTopic.user_tools[Object.keys(this.chatTopic.user_tools)[0]])
        }

        // this.selectedChatTool=this.chatTopic.user_tools[Object.keys(this.chatTopic.user_tools)[0]]
    },
    directives: {
    closeable: closeableDirective // Register the directive
  },
    computed:{
      ...mapState({
        loggedIn: (state) =>state.loggedIn, 
        modelConversation:(state)=>state.modelConversation,
        chatTopic: (state)=>state.chatTopic,
        selectedTopicTool: (state)=>state.selectedTopicTool,
        darkMode: (state)=>state.darkMode
      }),
      requestModel(){
        let newObj= Object.fromEntries(
        Object.entries(this.selectedTopicTool.tool_fields).map(([key, value]) => [key, value.type === "str" ? "" : null])
        );
        return newObj
      },
      showSubmitButton(){
        if(!this.selectedTopicTool || !('tool_fields' in this.selectedTopicTool))
        {
          return true 
        }
        let newObj= Object.fromEntries(
        Object.entries(this.selectedTopicTool.tool_fields).map(([key, value]) => [key, value.type === "str" ? "" : null])
        );
        if(Object.values(newObj).length==1)
        {
          return false
        }
        return true
      },
      itemsContainButton(){
      for( var field in this.selectedTopicTool.tool_fields)
        {
        const tool_obj=this.selectedTopicTool.tool_fields[field]
        if('type' in tool_obj && tool_obj.type=='button')
        {
          return true
        }
      }
      return false
      },
      modelConversation:{
        get(){
          return this.$store.state.modelConversation;
        },
        set(value){
          this.setModelConversation(value);
        }
      },
      getFieldComponent(){
        return (field)=>{
          switch(field.type){
            case 'str':
              return 'TextFormField' 
            case 'document':
              return 'DocumentUploader'
            case 'button':
              return 'ButtonFormField'
            case 'boolean':
              return 'CheckboxFormField';
            case 'model_options':
              return 'ModelOptionsFormField';
          }
          if(field.type=='document')
            return 'DocumentUploader'
          return 'textarea'
        }
      },
      lastMessage(){
        return this.modelConversation[this.modelConversation.length-1]
      },
      sendIconDisabled(){
        if(this.loading==true)
        {
          return true;
        }
        if(this.selectedRequestOption && 'value' in this.selectedRequestOption && this.selectedRequestOption.value==0 && this.project=='')
        {
          return true;
        }
        if(this.information=='')
        {
          return true;
        }
        return false;
      }

      },
    // Please reference store/readme.md file for all API calls
    watch:{
      information()
      {
        this.checkResize();
      },
      project(){
        this.checkResize(true);
      },
      largeChecked(){
        this.$store.dispatch('updateLargeModel',this.largeChecked)
      }
    },
    methods: {
      ...mapMutations({
        setModelConversation:'setModelConversation'
      }),
      updateField(value,field){
        if(field)
        {
          field.value=value
        }
      },  
      shouldTakeFullWidth(field){
        if('max_width' in field && field.max_width==false)
        {
          return false
        }
        //? if the field is one of the preset ones or it has max width set as false
        if( ('max_width' in field && field.max_width==true) || (field.type && this.maxWidthComponents.includes(field.type)))
        {
          return true
        }
        return false
      }, 
      checkShowDown(){
        this.$nextTick(() => {
        if(this.$refs && this.$refs.pageContent && !this.loading)
        {
          return this.showDownArrow=this.$refs.pageContent.scrollTop + this.$refs.pageContent.clientHeight +1 < this.$refs.pageContent.scrollHeight;
        }
        else{
          this.showDownArrow=false
        }
      });
      },
      selectUserTool(toolSelected){
        // this.selectedChatTool=this.chatTopic.user_tools[toolSelected];
        this.$store.commit('updateSelectedTopicTool', this.chatTopic.user_tools[toolSelected])
        this.toolsActive = false;
      },
      checkKeyPressSubmit(e)
      {
        if(e.ctrlKey || e.shiftKey || e.altKey || e.metaKey)//||this.information.includes('\n'))//? include this if you want to allow the user to press enter after one of the other options
        {
          if(e.ctrlKey||e.altKey)
          {
            this.information+="\n";
          }
        }
        else{
          e.preventDefault();
          this.sendRequest();
        }
      },
      getToolFieldsAndValues(){
        let fieldsAndValues={}
        for(let field in this.selectedTopicTool.tool_fields){
          if('value' in this.selectedTopicTool.tool_fields[field] && this.selectedTopicTool.tool_fields[field].value!='',this.selectedTopicTool.tool_fields[field].value!=null)
          {
            fieldsAndValues[field]=this.selectedTopicTool.tool_fields[field].value
          }

        }
        return fieldsAndValues
      },

      checkForMissingFields(){
        var missing=[]
      for(var field in this.selectedTopicTool.tool_fields){
        let foundField=this.selectedTopicTool.tool_fields[field]
        if('required' in foundField && foundField.required==true && !('value' in foundField && foundField.value!='') )
        {
          missing.concat(field)
        } 
      }
      if(missing.length>0)
      {
        console.error('We are missing some fields',missing)
      }
      return missing
      },

      findQuestionStringValue(fields,fieldName,fieldObject){
        var displayText=null
        if(fieldName in fields)
          {
            displayText=fields[fieldName].toString()
          }
          else if('tooltip' in fieldObject)
          {
            displayText=fieldObject.tooltip.toString()
          }
          else if ('placeholder' in fieldObject)
          {
            displayText=fieldObject.placeholder.toString()
          }
          return displayText;
      },

      sendRequest(displayText=null){
        displayText='';
        let fields=this.getToolFieldsAndValues();
        let missing=this.checkForMissingFields();
        let displayFields=Object.fromEntries( Object.entries(this.selectedTopicTool.tool_fields).filter(([key,value])=> 'user_display' in value && value.user_display==true && key!=null));
        let foundLength=Object.keys(displayFields).length;
        if(foundLength==0 && 'user_query' in fields)
        {
          displayText=fields['user_query']
        } 
        else if(foundLength==1)
        {
          let usedField=Object.keys(displayFields)[0]
          displayText=this.findQuestionStringValue(fields,usedField,displayFields[usedField])
        }
        else if (foundLength>1)
        {
          Object.entries(displayFields).forEach(([key,value])=>{
            displayText+=key.toString()+": "+this.findQuestionStringValue(fields,key,value)+" \n";
          })
        }
        
        // if(this.loading==true||this.requestModel['user_query']=='' || missing.length>0)
        if(this.loading==true|| missing.length>0)
        {
          console.error('There was an issue or we have it loading'+this.loading+"the length of missing is "+missing.length)
          return;
        }
        // if(this.$refs.historic && this.$refs.historic.historicSelected)
        // {
        //   this.$refs.historic.historicSelected={};
        // }
        this.loading=true;
        this.goToListBottom();

        let userQueryData={
          modelResponse:false,
          // user_query:this.requestModel['user_query']||displayText,
          textToDisplay:displayText,
          // query_data:{
          //   project:this.project,
          // }
        }
        userQueryData={...userQueryData,...fields}
        this.modelConversation.push(userQueryData);
        this.getGeneralResponse(fields);

        // switch(this.selectedRequestOption.value){
        //   case 0:
        //     this.getEmailResponse(this.information,this.project);
        //   break;

        //   default:
        //     this.getGeneralResponse(this.information,this.selectedRequestOption.value);
        //   break;
        // }
        this.clearTextAreas();
      },
      resetInput()
      {
        this.information="";
        this.project="";
      },
      clearTextAreas() {
      for (let field_name in this.selectedTopicTool.tool_fields) {
        if ('clear_after' in this.selectedTopicTool.tool_fields[field_name] && this.selectedTopicTool.tool_fields[field_name].clear_after==false) {
          continue;
        } 
        if (this.requestModel[field_name]) {
          this.requestModel[field_name] = ''; 
        }
        let field=this.selectedTopicTool.tool_fields[field_name]
        if('value' in field && field.type!='boolean')
        {
          field.value=null
        }
      }
    },
      checkResize(project=false){
        project
        //!! This was used to have the text box grow but does not work with duplicates Look to see if there is an alternative
      // const textarea = project?this.$refs.projectQuery: this.$refs.textAreaQuery;

      // textarea.style.height = `auto`; // Set the height to the scrollHeight
      // if(textarea.scrollHeight>textarea.clientHeight)
      // {
      //   textarea.style.height = `${Math.min(textarea.scrollHeight-10,85)}px`; // Set the height to the scrollHeight
      // }
      },
      goToListBottom(){
        this.$nextTick(()=>{
            this.$refs.after?.scrollIntoView({behaviour:'smooth',block: "end", inline: "nearest"});
          })
      },
      requestOptionSelected(option)
      {
        this.selectedRequestOption=option;
      },
      historicRecordChosen(records)
      {
        this.modelConversation=[];
        this.loading=true;
        this.goToListBottom();
          // this.$store.dispatch('getSessionRecords',historicRecord.chat_session_id).then((records)=>{
            for(var record of records){
              this.modelConversation.push(record)
            }
            this.loading=false;
          //   this.$nextTick(() => {
          //     const cont=this.$refs.conversation;
          //     cont.scrollTop = cont.scrollHeight;
          // });
            this.goToListBottom();
            this.checkShowDown();
        // }).catch((err)=>{
        //   this.loading=false;
        //   console.error('We got an error while getting the specifics',err)
        //   if(this.lastMessage)
        // {
        //   this.lastMessage.success=false
        //   this.lastMessage.response=err
        // }
        // })
        // this.$store.dispatch('getSpecificRecord',historicRecord.chat).then((records)=>{
        //   this.loading=false;

        // }).catch((err)=>{
        //   this.loading=false;
        //   console.error('We got an error while getting the specifics',err)

        // })
      },
      getEmailResponse(information,project){
        this.goToListBottom();
        this.$store.dispatch('getEmailResponse',{
          "user_query":information,
          "project":project
        }).then((res)=>{
          if(res.status && res.status=='success' && res.results && res.results.response)
          {
            this.lastMessage.success=true;
            this.lastMessage.response = res.results.response;
            this.lastMessage.record_id=res.results.model_request_id;
            // this.modelConversation.push({
            //   modelResponse:true,
            //   queryType:0,
            //   success:true,
            //   response:res.results.response,
            //   record_id:res.results.model_request_id,
            //   queryData:{
            //     user_query:information,
            //     project:project,
            //   }
            // })
            // this.responseText=res.results.response;
          }
          else if(res.status && res.status=='failed' && res.message)
          {
            this.lastMessage.success=false;
            this.lastMessage.response=res.message
          }
          else{
            this.responseText="Error: could not get email from server";
            this.lastMessage.success=false;
            this.lastMessage.response="Error: could not get email from server"
          }
          this.loading=false;
          this.checkShowDown();
        }).catch((err)=>{
          this.loading=false;
            this.lastMessage.success=false;
            this.lastMessage.response=err;
            this.checkShowDown();
        })
      },


      getGeneralResponse(information){
        //! THIS CAN CHANGE WHEN THE SERVER IS UPDATED
        // let user_data={
          // "topic_tool_id":this.selectedTopicTool.topic_tool_id ?? this.selectedTopicTool.chat_tool_id
        // }
        let user_data=information
        user_data["topic_tool_id"]=this.selectedTopicTool.topic_tool_id ?? this.selectedTopicTool.chat_tool_id

        this.$store.dispatch('getModelResponse',user_data).then((res)=>{
          if(res.status && res.status=='success' && res.results && 'response' in res.results && res.results.response!=null)
          {
            // let respObj={
            //   modelResponse:true,
            //   queryType:queryType,
            //   success:true,
            //   response:res.results.response,
            //   record_id:res.results.model_request_id,
            //     user_query:information,
            //   }:{user_query:information}
            // }
            this.lastMessage.success=true;
            this.lastMessage.response=res.results.response;
            this.lastMessage.record_id=res.results.model_request_id;
            this.lastMessage.custom_response= res.results.custom_response? res.results.custom_response:null;
            this.lastMessage.responseObj=res.results;
          }
          else if(res.status && res.status=='failed' && res.message)
          {
            this.lastMessage.success=false;
            this.lastMessage.response=res.message;
          }
          else{
            try{
              JSON.stringify(res.results)
              this.lastMessage.success=true;
              this.lastMessage.response=JSON.stringify(res.results)
            } catch(e)
            {
              this.lastMessage.success=true;
              this.lastMessage.response=res.results
            }
            // this.responseText="Error: could not get a response from server";
            // this.lastMessage.success=false;
            // this.lastMessage.response="Error: could not get a response from server"
          }
          this.loading=false;
          this.checkShowDown();
        }).catch((err)=>{
          this.loading=false;
          this.responseText="Error: not able to get response "+err
            this.lastMessage.success=false;
            this.lastMessage.response=err;
            this.checkShowDown();
        })
      },

      logOut()
      {
        // this.$store.dispatch('showLoggedOutMessage',true)

        this.$store.dispatch("logUserOut");
      },


      applyGreyedOutClass() {
      // Check if chatTopic.user_tools exists and apply the class conditionally
      if (this.chatTopic && this.chatTopic.user_tools) {
        return Object.keys(this.chatTopic.user_tools).length <= 1 ? 'greyed-out' : '';
      }
      return ''; // Return an empty string if chatTopic.user_tools is not defined yet
    },

    closeToolMenu() {
      this.toolsActive = false
    }
//       loginGEMS() {
//         this.$store.dispatch("loginGEMS", {
//           email: "sunny@sisfoundation.ca",
//           password: "Cuc25169sis",
//         });
//       },
    },
  };
  </script>
  
  <style   lang="scss" >
  @use "./styles/ModelRequest.scss" ;
  @use "../assets/styles/main.scss" ;
  </style>
  