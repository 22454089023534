<template>
  <div class="documentUploader" >
    <input type="file" ref="fileInput" multiple @change="handleFileChange" style="display: none">
    <button @click="selectFiles" class="upload-button">
      <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" class="uploadSVG">
        <g id="Upload">
          <g id="Background">
            <circle id="Ellipse1" cx="60" cy="61" r="59" fill="#D9D9D9" />
          </g>
          <g id="Arrow">
            <path id="Polygon 1"
              d="M56.8539 24.6409C58.0433 23.2491 60.1911 23.2379 61.3949 24.6173L91.65 59.2846C93.3436 61.2252 91.9654 64.2572 89.3898 64.2572H29.5098C26.9489 64.2572 25.5654 61.2552 27.2291 59.3083L56.8539 24.6409Z"
              fill="white" />
            <path id="Rectangle 1"
              d="M47.586 61.9523H72.172V94.8313C72.172 97.0405 70.3812 98.8313 68.172 98.8313H51.586C49.3769 98.8313 47.586 97.0405 47.586 94.8313V61.9523Z"
              fill="white" />
          </g>
        </g>
      </svg>
    </button>
    <button v-if="selectedFiles.length > 0" @click="uploadFiles" class="upload" >Upload</button>
    <ul v-if="selectedFiles.length > 0" class="file-list" >
      <li v-for="file in selectedFiles" :key="file.name" class="file-list-item">{{ file.name }}
        <button @click="removeFile(file)" class="file-list-delete" >
          <font-awesome-icon :icon="['fas', 'x']" size="xs" class="file-list-delete-x"/>
        </button>
      </li>
    </ul>
  </div>
</template>
  
  <script>
  export default {
    methods: {
      handleFileChange(event) {
        this.selectedFiles = Array.from(event.target.files);
      },
      uploadFiles() {
        if (this.selectedFiles.length > 0) {
            const formData = new FormData();
            this.selectedFiles.forEach((file) => {
                formData.append("files",  file);
            });
            const topic=["9383be67-5b3f-4668-bdd7-f57ea9dc6c46"]
            const fileTopics=Array(this.selectedFiles.length).fill(topic)
            formData.append("topics", JSON.stringify(fileTopics));
            // send the formData object
          this.$store.dispatch("uploadDocuments",formData).then((result)=>{
            result
        });
        }
      },
      selectFiles() {
      this.$refs.fileInput.click();
    },
    removeFile(file) {
      const index = this.selectedFiles.indexOf(file);
      if (index !== -1) {
        this.selectedFiles.splice(index, 1);
      }
    },
    },
    data() {
      return {
        selectedFiles: []
      };
    }
  };
  </script>

  <style  lang="scss"  >
  @use "./styles/UploadButton.scss" ;
  </style>
  