<template>
  <div class="view-container">
    <VueFlow
      :nodes="nodes"
      :edges="edges"
      :class="{ darkMode }"
      class="basic-flow"
      :default-viewport="{ zoom: 1.5 }"
      :min-zoom="0.2"
      @pane-ready="onPaneReady"
      @init="onInit"
      @node-drag-stop="onNodeDragStop"
      @connect="onConnect"
      :connection-mode="'strict'"
      @nodes-initialized="nodesInitialized" 
      :max-zoom="4"
      :default-edge-options="{ type: 'smoothstep' }"
      ref="vueFlowRef"
      @connect-start="startConnecting"
      @connect-end="clearHighlights"
      @edge-click="edgeClicked"
      @pane-click="clearHighlights"
      @node-click="nodeClicked"
      @update:edges="edgeUpdate"
      @update:nodes="nodeUpdate"
      :zoom-on-double-click="false"
    >
    <!-- :nodes-draggable="!nodeBeingEdited"
    :pan-on-drag="!nodeBeingEdited" -->
    <template #node-custom-table="customNodeProps">
      <CustomGraphNode :targetNodes="targetNodes" :connecting="connecting" :horizontal="isHorizontal" :nodeData="customNodeProps" />
    </template>
    <template #node-task="customNodeTaskProps">
      <CustomTaskNode :targetNodes="targetNodes" @nodeContentUpdated="nodeContentUpdated" @editNodeUpdated="editNodeUpdated" :selectedNode="selectedNodeId" :connecting="connecting" :horizontal="isHorizontal" :nodeData="customNodeTaskProps" />
    </template>
      <Controls position="top-left"  :class="['controls', darkMode ? 'controls-dark' : '']">
        <!--? This can be used to override the buttons that exist by default -->
      <!--  <template v-slot:control-zoom-in>
          <ControlButton  title="Zoom In"  >

            </ControlButton>
        </template> -->
        <ControlButton title="Reset" @click="resetTransform">
          <font-awesome-icon :icon="['fas', 'undo']" />
        </ControlButton> 
        <!-- <ControlButton title="Shuffle Node Positions" @click="updatePos">
          <Icon :name="'update'" />
        </ControlButton> -->
        <ControlButton title="Take Screenshot" @click="takeScreenshot">
          <font-awesome-icon :icon="['fas', 'image']" />
        </ControlButton>
        <ControlButton title="Copy Image" @click="copyImage">
          <font-awesome-icon :icon="['fas', 'copy']" />
        </ControlButton>
        <!-- <ControlButton title="Log `toObject`" @click="logToObject">
          <Icon :name="'log'" />
        </ControlButton> -->
        <ControlButton :title="'Order '+(isHorizontal?'Horizontally':' Vertically')" @click="layoutGraph(true)">
          <font-awesome-icon v-if="isHorizontal" :icon="['fas', 'arrows-alt-h']" />
          <font-awesome-icon v-else :icon="['fas', 'arrows-alt-v']" />
        </ControlButton>
        <ControlButton title="Save State" @click="saveGraphState">
          <font-awesome-icon :icon="['fas', 'save']" />
        </ControlButton>
        <ControlButton title="Add Node" @click="addNewNode">
          <font-awesome-icon :icon="['fas', 'plus-square']" />
        </ControlButton>
      </Controls>
      <Background  pattern-color="#aaa" :gap="16" />
      <MiniMap />
    </VueFlow>
  </div>
</template>

<script>
// import { VueFlow,  MarkerType } from '@vue-flow/core'
import { VueFlow, Position } from '@vue-flow/core'
import { Background } from '@vue-flow/background'
import { ControlButton, Controls } from '@vue-flow/controls'
import { MiniMap } from '@vue-flow/minimap'
import CustomGraphNode from './CustomGraphNode.vue'
import CustomTaskNode from './CustomTaskNode.vue'
import dagre from '@dagrejs/dagre'
import { mapState } from "vuex";
import {toPng as ElToPng} from 'html-to-image';

export default {
  name: "BasicFlow",
  
  components: {
    VueFlow,
    Background,
    MiniMap,
    Controls,
    ControlButton,
    CustomGraphNode,
    CustomTaskNode
  },
  props:
  {
    componentData: Object,
  },
  computed:{
  ...mapState({
      darkMode:(state)=>state.darkMode,
    }),
    nodeBeingEdited(){
      if (this.selectedNodeId !=null && this.updatingNodeId!=null && this.updatingNodeId==this.selectedNodeId) {
        return true;
      }
      // if (this.selectedNodeId == null) {
      //   return false;
      // }
      return false
    },
},
  data() {
    return {
      flowInstance:null,
      edges:[],
      nodes:[],
      isHorizontal:true,
      savedState:null,
      connecting:null,
      targetNodes: [],
      selectedNodeId:null,
      updatingNodeId:null,
    }
  },

  mounted() {
    this.savedState=this.componentData
    // const {addEdges, setViewport, toObject } = useVueFlow()
    // // useVueFlow();
    // this.addEdges = addEdges;
    // // Save methods for access within the component
    // this.setViewport = setViewport 
    // this.toObject = toObjectc
  },

  methods: {
    nodeContentUpdated({nodeID,nodeData}){
      const task=this.nodes.find(node=>node.id==nodeID)
      if(task){
        task.data=nodeData
      }
      //todo: look at if we need to save to the db ot not 
    },
   editNodeUpdated(node){
    this.updatingNodeId=node
    this.selectedNodeId=this.updatingNodeId
    },
    
  nodeClicked(event) {
    this.clearHighlights();
    this.selectedNodeId = event.node.id;
    var filteredEdges = this.edges
      .filter(edge => edge.source === event.node.id.toString() || edge.target === event.node.id.toString())
      filteredEdges.forEach(edge => {
        var foundEdge=this.flowInstance.findEdge(edge.id)
        if(foundEdge){
          foundEdge.selected=true
        }
      })
      this.targetNodes = filteredEdges
      .flatMap(edge => [edge.source, edge.target].filter(node => node !== event.node.id.toString()))
      this.connecting='true';
  },
    clearHighlights(){
      this.connecting=null;
      this.targetNodes=[];
      this.selectedNodeId=null;
      if(this.flowInstance && this.edges.length>0){
        this.edges.forEach(edge => {
          var foundEdge=this.flowInstance.findEdge(edge.id)
          if (foundEdge) {
            foundEdge.selected=false
          }
        })
      }

    },
    edgeUpdate(ed){
      this.clearHighlights()
      this.edges=ed;
    },
    nodeUpdate(nd){
      this.clearHighlights()
      this.nodes=nd;
    },
    edgeClicked(event) {
      this.clearHighlights()
      this.connecting='true';
      this.targetNodes = [event.edge.source, event.edge.target];
      event.edge.selected=true;
    },
    startConnecting(event) {
      this.clearHighlights()
      this.connecting=event.nodeId;
      // this.targetNodes = this.edges
      // .filter(edge => edge.source === event.nodeId.toString())
      // .map(edge => edge.target);
      this.targetNodes = this.edges
      .filter(edge => edge.source === event.nodeId.toString() || edge.target === event.nodeId.toString())
      .flatMap(edge => [edge.source, edge.target].filter(node => node !== event.nodeId.toString()));
    },
    async nodesInitialized() {
      if(this.savedState && 'has_updated' in this.savedState && this.savedState.has_updated==true) 
      {
          let tempNodes=[...this.nodes]
          await this.animateAddNodes(tempNodes)
          this.flowInstance.fitView()
      }
      else{
        await this.layoutGraph()
      }
    },
    async animateAddNodes(addedNodes, interval=300){
      this.nodes=[]
      let tempEdges=[...this.edges] //? this is mostly to stop the 
      this.edges=[]
      for (var node of addedNodes) {
        this.nodes.push(node)
        let ids=this.nodes.map((node)=>node.id)
        let filterEdges=tempEdges.filter(edge =>  ids.includes(edge.source) && ids.includes(edge.target) );
        this.edges.push(...filterEdges)
        tempEdges=tempEdges.filter(edge =>{
          return !filterEdges.some(filteredEdge => edge.id === filteredEdge.id)
        } );
        await new Promise(resolve => setTimeout(resolve, interval));
      }
      // if(tempEdges.length>0) this.edges.push(...tempEdges)
      // this.edges=tempEdges
    },
    updatePos() {
      this.nodes = this.nodes.map((node) => ({ 
        ...node,
        position: { x: Math.random() * 400, y: Math.random() * 400 }, 
      }))
      // this.layoutGraph()
    },
    addNewNode(){
      let lastNode=this.nodes[this.nodes.length-1]
      let newNode={
        id:(parseInt(lastNode.id)+1).toString(),
        type:lastNode.type,
        position:{x:Math.random()*this.flowInstance.dimensions.value.width,y:Math.random()*this.flowInstance.dimensions.value.height},
        class: lastNode.class,
        data:{task:''},
      }
      this.nodes.push(newNode)
      // this.$nextTick(()=>{this.layoutGraph()}) 
      //todo find a way to have the new node being added without being put over anything else 
      console.warn('need to find a way to add the node to the graph without any overlapping ')
      
    },
    saveGraphState(){
      let graphObject=this.flowInstance.toObject();
      let updatedState={nodes:graphObject.nodes,edges:graphObject.edges,is_horizontal:this.isHorizontal}
      let updateObj={
        'custom_response_id':this.componentData.custom_response_id,
        "updated_data":updatedState
      }
      this.$store.dispatch('updateCustomResponse',updateObj).then((updateRes)=>{
        if(updateRes.status && updateRes.status=='success')
        {
          this.savedState.has_updated=true;
          this.savedState.response_data=updatedState;
        } 
        else{
          console.error('Error: '+updateRes.message)
        }
      })
    },
    onPaneReady(instance) {
      instance.fitView()
      this.flowInstance=instance;
    },


    copyImage(){
      return this.takeScreenshot({},true).then(async (returnedBlob)=>{
        const clipboardItem= new ClipboardItem({
          // "image/png":returnedBlob
          [returnedBlob.type]:returnedBlob
        })
          navigator.clipboard.write([clipboardItem]).then(()=>{
        }).catch(err => {
          console.error('Error copying to clipboard:', err);
        });
      })
    },



   async takeScreenshot(options={},return_blob=false){
    return new Promise((resolve, reject) => {
      
      const fileName= options.filename?? `swift-ai-screenshot-${Date.now()}`;
      const exclusionClasses=['controls', 'vue-flow__minimap', 'vue-flow__background'] //? the vue-flow__background can be removed from here and it will show the background as defined
      //? this can be brought back if we want the screenshot to include the entire thing it needed some timeout to work initially and 2 nextticks was not enough
      // var oldViewport=this.flowInstance.getViewport()
      // this.flowInstance.fitView()
      const filter=(node)=>{
        return !exclusionClasses.some((exclusionClass)=>node.classList?.contains(exclusionClass))
      };

      ElToPng(this.$refs.vueFlowRef.$el, {useCors: true,filter:filter,backgroundColor: this.darkMode? '#2d3748':'white'}).then(async (data)=>{
        if (return_blob) {
          const response=await fetch(data);
          const blob=await response.blob();
          resolve(blob)
          return blob
        }
        // this.flowInstance.setViewport(oldViewport) //? also bring this back if we want the viewport to go back to what it was 
        const link = document.createElement('a');
        link.download = `${fileName}.png`;
        link.href = data
        link.click();
        resolve(data)
        return data;
      }).catch((err)=>{
        console.error('there was an error while getting the image ',err) 
        reject(err)
      })
    })
    },
    async layoutGraph(swapDirection=false){
      if(swapDirection==true)
    {
      this.isHorizontal=!this.isHorizontal
    }
      // Look here https://vueflow.dev/examples/layout.html 
      if(!this.nodes)
      {
        return
      }
      var graph = new dagre.graphlib.Graph()
      graph.setDefaultEdgeLabel(()=>{return{};})
      graph.setGraph({rankdir:this.isHorizontal?'TB':'LR'}) //? This seems to be reversed for some reason, I am not sure why 
      //? all these are used for the grouping part when there are no edges or it would make a large line
      let groupSize=Math.floor( Math.sqrt(this.nodes.filter((node)=>this.isNodeInEdges(node.id)==false).length))
      let previousNode=null
      let groupCount=0
      this.nodes.forEach((node)=>{
        const nodeFound=this.flowInstance.findNode(node.id)
        if(this.isNodeInEdges(node.id)==false) //? this section is used to create phantom edges to make it more layed out. Previously it would make one large line
      {
        if(previousNode!=null)
        {
          graph.setEdge(previousNode,node.id);
          groupCount++;
        }
        else{
          previousNode=node.id;
          groupCount++;
        }
        if(groupCount>=groupSize)
        {
          previousNode=node.id;
          groupCount=0;
        }
      }
        graph.setNode(node.id, {width:nodeFound.dimensions.width || 150 ,height:nodeFound.dimensions.height || 50})
      });

      this.edges.forEach((edge)=>{
        graph.setEdge(edge.source,edge.target)
      })
      dagre.layout(graph)
      let newNodes= this.nodes.map((node)=>{
        const nodeWithPos=graph.node(node.id)
        return {...node,
          targetPosition: this.isHorizontal ? Position.Left : Position.Top,
          sourcePosition: this.isHorizontal ? Position.Right : Position.Bottom,
          position:{x:nodeWithPos.x,y:nodeWithPos.y}
        }
      });
     await this.animateAddNodes(newNodes);
      // this.nodes=newNodes
      this.$nextTick(() => {
        this.edges=[...this.edges]
        this.flowInstance.fitView()
      });

    },

    setGraphValues(dataSource){
      this.edges=[...dataSource.response_data.edges]??[];
      this.nodes=[...dataSource.response_data.nodes]??[];
      this.isHorizontal=dataSource.response_data.is_horizontal??true;
      this.hasUpdated=dataSource.has_updated??false;
    },

    isNodeInEdges(nodeId){
      return this.edges.some((edge)=>edge.source==nodeId || edge.target==nodeId)
    },

    async onInit(vueFlowInstance) {
      this.setGraphValues(this.componentData)
      // this.nodes=this.passedNodes
      this.flowInstance=vueFlowInstance;
    },
    onNodeDragStop({ event, nodes, node })  {
      event;nodes;node
    },
    onConnect(connection) {
      if(connection.source==connection.target || this.edges.some((edge)=>(edge.source==connection.source && edge.target==connection.target)||(edge.source==connection.target && edge.target==connection.source))) 
      {
        return
      }
      this.flowInstance.addEdges(connection)
      this.edges.push({'id':`e${connection.source}-${connection.target}`,'source':connection.source.toString(),'target':connection.target.toString()})
    },
    async resetTransform() {
      if (this.savedState ) {
        this.setGraphValues(this.savedState)
        this.$nextTick(()=>{this.flowInstance.fitView()})
      }
      else{
        await this.layoutGraph()
      }
    },
  },
}
</script>


<style   >
@import '@vue-flow/core/dist/style.css' ;
@import '@vue-flow/controls/dist/style.css' ;
/*@use 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.41.2/dist/style.css';*/
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.41.2/dist/theme-default.css' ;
/*@use 'https://cdn.jsdelivr.net/npm/@vue-flow/controls@latest/dist/style.css';*/
/*@use 'https://cdn.jsdelivr.net/npm/@vue-flow/minimap@latest/dist/style.css';*/
/*@use 'https://cdn.jsdelivr.net/npm/@vue-flow/node-resizer@latest/dist/style.css';*/

.basic-flow {
  width: 100%;
  height: 40vh;
}

.view-container {
  overflow: auto;
  width: 100%;
  height: 100%;
  border: solid black 1px;
  box-sizing: border-box;
}


.vue-flow__minimap {
  transform: scale(75%);
  transform-origin: bottom right;
}

.basic-flow.darkMode {
    background:#2d3748;
    color:#fffffb
}

.basic-flow.darkMode .vue-flow__node {
    background:#4a5568;
    color:#fffffb
}

.basic-flow.darkMode .vue-flow__node.selected {
    background:#333;
    box-shadow:0 0 0 2px #2563eb
}

.basic-flow .vue-flow__controls {
    display:flex;
    flex-wrap:wrap;
    justify-content:center
}

.basic-flow.darkMode .vue-flow__controls {
    border:1px solid #FFFFFB
}

.basic-flow .vue-flow__controls .vue-flow__controls-button {
    border:none;
    border-right:1px solid #eee
}

.basic-flow .vue-flow__controls .vue-flow__controls-button svg {
    height:100%;
    width:100%
}

.basic-flow.darkMode .vue-flow__controls .vue-flow__controls-button {
    background:#333 !important;
    fill:#fffffb;
    color:#fffffb;
    border:none
}


.basic-flow.darkMode .vue-flow__controls .vue-flow__controls-button:hover {
    background:#4d4d4d
}

.basic-flow.darkMode .vue-flow__edge-textbg {
    fill:#292524
}

.basic-flow.darkMode .vue-flow__edge-text {
    fill:#fffffb
}
button.darkMode{
  fill:#292524;
}

.vue-flow__edge-path{
  stroke-width: 2;
  stroke: #b1b1b7;
  fill: none;
}

.vue-flow-darkMode .control-button {
  background-color: #333; /* Example dark mode color */
  color: #fff;
}
</style>
