<template>
    <div id="ResetPasswordPage"  :class="['loginDiv', darkMode ? 'darkModeTheme' : '']" >
      <br/>
      <img src="../assets/images/energy_ai_logo.png" alt="SWIFT Energy AI"/>
      <br/>
      <div class="main-section" v-show="!resetIssue">
        <Spinner_2 v-if="loading" class="login-spinner"/>
        <div class="title-section">
          <p>Reset Password</p>
          <span>Please enter your new password and then click Reset Password</span>
        </div>
        <!-- <p>Please enter your email and password</p> -->
        <div class="input-area">
          <div class="input-container">
            <input  @keydown="checkCaps" v-bind:class="{'input-error':passwordState==false}"
                   :type="passwordType" v-model="password" placeholder="Password" required>
            <font-awesome-icon @click="passwordType='text'" class="input-button" :icon="['fas', 'eye']"
                               v-if="password && passwordType=='password'"/>
            <font-awesome-icon class="input-button" @click="passwordType='password'" :icon="['fas', 'eye-slash']"
                                v-if="password && passwordType=='text'"/>
          </div>
          <div class="input-container">
            <input  @keydown="checkCaps" v-bind:class="{'input-error':confirmPasswordState==false}"
                   :type="confirmPasswordType" v-model="confirmPassword" placeholder="Confirm Password" required>
            <font-awesome-icon @click="confirmPasswordType='text'" class="input-button" :icon="['fas', 'eye']"
                               v-if="confirmPassword && confirmPasswordType=='password'"/>
            <font-awesome-icon class="input-button" @click="confirmPasswordType='password'" :icon="['fas', 'eye-slash']"
                                v-if="confirmPassword && confirmPasswordType=='text'"/>
          </div>
          <Turnstile @tokenUpdated="(newToken)=>updatedToken=newToken" />
            <PasswordChecker v-show="!passwordsValid" :password="password" :confirmPassword="confirmPassword" @passwordsValidUpdated="(updated)=>passwordsValid=updated" />
        </div>
        <div v-if="capsWarning" class="loginWarning" data-cy="caps-warning">
          <p>WARNING: Caps lock on. <br/>Your password may be incorrect</p>
        </div>
        <div class="btn-login">
          <button v-bind:class="{'inactive-button':!canReset }" class="btn"
                  @click="resetPassword()">Reset Password
          </button>
        </div>
        <!--<div class="other-login">
          <button class="btn" @click="testLogin()">Test Google Login</button>
          <GoogleLogin :callback="testCallback" />
        </div> -->
      </div>
  
      <div v-if="resetIssue" id="loginresults" data-cy="login-issue">
        <p v-if="resetToken==null || resetToken==''">
          There is an issue with your link.<br>
          The reset token was not passed in properly.<br>
          Please try again or copy the link directly from the email.
        </p>
      </div>
      <ErrorMessageModal
      v-if="resetIssue || passwordResetSuccess"
      :errorTitle="messageTitle || 'Password Reset Error'"
      :errorBody="messageBody ||'There was an error while resetting your password. Please try again. If you continue having issues please resend the email.'"
      @close="closeModal"
      />
    </div>
  </template>
  
  <script>
  import Spinner_2 from "../components/Spinner_2.vue";
  import Turnstile from "@/components/Turnstile.vue";
  import {mapState} from "vuex";
  import ErrorMessageModal from "@/components/modals/ErrorMessage.vue";
  import global from "@/util/GlobalFunctions.js";
  import PasswordChecker from "@/components/PasswordChecker.vue";
  export default {
    name: "ResetPassword",
    mixins: [global],
    components: {
      Spinner_2,
      Turnstile,
      ErrorMessageModal,
      PasswordChecker
    },
    data() {
      return {
       password:"",
       confirmPassword: "",
       resetToken:null,
       resetIssue:true,
       loading:false,
       passwordType:"password",
       confirmPasswordType:"password",
       capsWarning: false,
       updatedToken: '',
       messageBody:'',
       messageTitle:'',
       showResetError:true,
       passwordResetSuccess:false,
       passwordsValid:false,
      };
    },
    computed: {
      ...mapState({
        darkMode: state => state.darkMode
      }),
      canReset(){
        return this.passwordsValid && this.updatedToken && this.updatedToken!='';
        // return this.passwordState && this.confirmPasswordState && this.resetToken!=null && this.updatedToken && this.updatedToken!='';
      },
      passwordState() {
        if (this.password === "") {
          return null;
        } else {
          // return this.password.length >= 6;
          return this.passwordIsValid(this.password);
        }
      },
      confirmPasswordState() {
        if (this.confirmPassword === "") {
          return null;
        } else {
          return this.passwordState==true && this.confirmPassword === this.password;
        }
      }
    },
    mounted () {
      this.resetModal();
      if(this.$route && this.$route.query && this.$route.query.token) {
        this.resetToken=this.$route.query.token;
      }
      else{
        this.messageBody="There is an issue with your link. The reset token was not passed in properly. Please try again or copy the link directly from the email.";
        this.resetToken=null;
        this.resetIssue=true;
      }
    },
    methods: {
      resetModal(){
        this.passwordResetSuccess=false;
        this.resetIssue=false;
        this.messageTitle="";
        this.messageBody="";
      },  
      closeModal(){
        if((this.passwordResetSuccess && !this.resetIssue)||this.resetToken==null){
          this.resetModal();
          this.$router.replace({path: "/"});
        }
        else //if(!this.passwordResetSuccess && this.resetIssue)
        {
          this.resetModal();
        }
      },
      resetPassword(){
        if(!this.canReset){
          console.error("canReset is false");
          return
        }
        this.loading=true;
        this.$store.dispatch('resetPassword', {
          password: this.password,
          token: this.resetToken,
        }).then((result) => {
          this.loading=false;
          this.password="";
          this.confirmPassword="";
          if(result)
          {
            this.messageTitle="Password Reset";
            this.messageBody="Your password has been reset. Please try to sign in with your new password.";
            this.passwordResetSuccess=true;
          }
          else{
            console.error('There was an error resetting the password');
            this.resetIssue=true;
          }
          return;
        }).catch((err) => {
          console.error("There was an error resetting the password",err);
          this.resetIssue=true;
          this.loading=false;
          this.password="";
          this.confirmPassword="";
        });
      },
      checkCaps: function (event) {
        //This function will check the caps lock for the password feild
        if (typeof event.getModifierState == 'function' && event.getModifierState("CapsLock")) {
          this.capsWarning = true;
        } else {
          this.capsWarning = false;
        }
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  @use "../assets/styles/main.scss" ;
  @use "./styles/SignIn.scss";
  </style>
  