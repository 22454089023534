<template>
    <div class="feedback-section" >
        <font-awesome-icon class="close-icon" v-tooltip="'Good Response'" @click="clickThumb(true)" :class="['close-icon', getThumbClass(true), darkMode ? 'dark-icon' : 'icon']" :icon="['fas','thumbs-up']" />
        <font-awesome-icon class="close-icon" v-tooltip="'Bad Response'" @click="clickThumb(false)" :class="['close-icon', getThumbClass(false), darkMode ? 'dark-icon' : 'icon']" :icon="['fas','thumbs-down']" />
        <font-awesome-icon  :class="[darkMode ? 'dark-icon' : 'icon', responseFeedback !== '' ? 'selected-thumb' : '']" class="feedback-icon" v-tooltip="'Provide Feedback'" @click="openFeedback()"  :icon="['fas','comment-alt']" />
        <Transition name="feedback">
        <div v-if="showFeedbackBox" class="feedback-box">
          <div class="feedback-exit">
            <font-awesome-icon class="feedback-exit-icon" :icon="['fas', 'x']" @click="closeFeedback()" /></div>
          <textarea ref="feedbackQuery" v-model="responseFeedback" spellcheck="true" placeholder="Enter your feedback"></textarea>
          <button
          type="button"
          class="btn-apply"
          @click="submitFeedback()">
          Ok
        </button>
      </div>
    </Transition>
        </div>
    </template>
    
    <script>
    import { mapState } from "vuex";
    export default {
      name: "UserFeedback",
      components: {
      },
      props:{
        initialFeedback:Object,
      },
      data() {
        return {
          showFeedbackBox:false,
          positive:null,
          responseFeedback:""
        };
      },
      
      mounted(){
      this.checkResize();

        if(this.initialFeedback)
      {
        if(this.initialFeedback.score!=null)
        {
          this.positive=this.initialFeedback.score>0;
        }
        if(this.initialFeedback.feedback!=null)
        {
          this.responseFeedback=this.initialFeedback.feedback;
        }

      }
      },
      computed: {
        ...mapState({
            darkMode: (state) => state.darkMode,
        }),

        feedbackObj(){
            return{  
                rating: this.positive!=null? +this.positive:null,
                feedback: this.responseFeedback
            }
        },
      },
      watch:{
        responseFeedback()
      {
        this.checkResize()
      },
      },
      methods: {
        checkResize(){ //Used to allow the text area to grow as needed 
        if(!this.showFeedbackBox)
        {
          return;
        }
            const textarea = this.$refs.feedbackQuery;

            textarea.style.height = `auto`; // Set the height to the scrollHeight
            if(textarea.scrollHeight>textarea.clientHeight)
            {
                textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
            }
      },
        clickThumb(thumbPositive)
      {
        if(thumbPositive==this.positive){
          this.positive=null;
        }
        else{
          this.positive=thumbPositive
        }
        // this.sendResponseRating()
        this.$emit('sendFeedback',this.feedbackObj)
      },
      getThumbClass(thumb)
      {
      if(thumb==this.positive)
      {
        return 'selected-thumb'
      }
      return null
      },
      openFeedback() {
            this.showFeedbackBox = true;
            // this.$nextTick(this.$nextTick( this.checkResize()));
            setTimeout(this.checkResize, 0); 
        },

        closeFeedback(){
          this.showFeedbackBox = false;
          // setTimeout(this.checkResize, 0); 
        },
        submitFeedback(){
        this.showFeedbackBox=false;
        this.$emit('sendFeedback',this.feedbackObj)
        // this.sendResponseRating()
      },

      },
    };
    </script>
    
    <style  lang="scss"  >
    @use "../assets/styles/main.scss" ;
    @use "./styles/ChatEntry.scss" ;
    @use "./styles/Buttons.scss" ;
    </style>
    