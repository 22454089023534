<template>
    <div class="line-container">
      <Line ref="chartRef" :data="initialChartData" :options="chartOptions" class="chart"/>
    </div>
  </template>
  
  <script>
import {Line} from 'vue-chartjs'
import { windingTestData } from './TestChartData.ts';
import zoomPlugin from 'chartjs-plugin-zoom';
import _ from 'lodash';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
)
  export default { 
    name:"LineChart",
    components: {
      Line  
    },
    props: {
        chartInfo:Object,
        refreshTime:Number,
        additionalPlugins:Object,
        commonChartOptions:Object
    },
    data() {
      return {
        loaded:10,
        chartOptions:{
            responsive: true,
            maintainAspectRatio: false,
        scales: {
          y: {
              title:{
                display:this.chartInfo?.labels?.y ? true:false,
                text:this.chartInfo.labels?.y??""
              },
              grid:this.commonChartOptions?.grid??{},
            },
            x:{
              ticks:{
              },
              title:{
                display:this.chartInfo?.labels?.x ? true:false,
                text:this.chartInfo.labels?.x??""
              },
              grid:this.commonChartOptions?.grid??{},
            }
      
        },
            indexAxis: 'x',
            elements:{
              point:{
                radius: 2  
              }
            },
            plugins:{
              ...this.additionalPlugins,
              legend:{
                display:true,
                labels:{
                  // color: 'rgb(255,99,132)',
                  font:{
                    size:11
                  }
                }
              },
              title:{
                display:this.chartInfo?.name ? true:false,
                text:this.chartInfo.name// "MP-VC-07 Winding Temperatures"
              },
              zoom:{
                pan:{
                  enabled: this.chartInfo.zoom??true,

                  onPan:()=>{
                    this.$emit('zoomPanChanged')
                  }
                },
                zoom:{
                  wheel:{
                    enabled: this.chartInfo.zoom??true,
                  },
                  pinch:{
                    enabled: this.chartInfo.zoom??true,
                  },
                  mode: 'xy',
                  onZoom:()=>{
                    this.$emit('zoomPanChanged')
                  },
   
                }
              }
            },

        }
      };
    },
    mounted(){
      // setInterval(()=>{
      //   if(this.loaded<400)
      // {
      //   this.loaded++;
      // }
      // },1000)

      const intervalId = setInterval(() => {
        // this.chartData.datasets[0].data=[this.vibrationsData[this.radarPosition].DEX,this.vibrationsData[this.radarPosition].DEY, this.vibrationsData[this.radarPosition].NDEX,this.vibrationsData[this.radarPosition].NDEY]
        this.loaded++;
        if (this.loaded >= this.chartInfo.data.labels?.length) {
          clearInterval(intervalId);
        }
      }, this.refreshTime); // 1000ms = 1s

    },
    computed: {
      windingTestData(){
        return windingTestData
      },
      initialChartData(){
        let tempData=_.cloneDeep(this.chartInfo.data)
      tempData.labels=tempData?.labels?.slice(0,this.loaded)
      for (var dataSet of tempData.datasets){
        let dataSetData=[...dataSet.data].slice(0,this.loaded)
        dataSet.data=dataSetData
      }
      return tempData;
      }
    },
  watch: {

    },
    methods: {

    }
  };
  </script>
  
  <style  lang="scss"  >
  @use "../../assets/styles/main.scss" ;
  .line-container{
      width: 100%;
      height: 100%;
  }
  .chart{
    width: 100%;
    height: 100%;
  }
  </style> 