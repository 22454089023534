<template>
    <div class="button-item" >
      <!-- <textarea v-if="!('required' in field && field['required'] && field_name=='user_query') && 'type' in field && field['type']=='str' " v-on:keydown.enter="checkKeyPressSubmit" ref="projectQuery" spellcheck="true" :class="[ darkMode ? 'dark-query-input' : 'query-input', 'project-input']" v-model="text_input" :placeholder="field_name.charAt(0).toUpperCase() + field_name.slice(1)" required></textarea> -->
       <!-- {{field_name}}:{{field}} -->
       <!-- <div v-if="field_name=='Submit'" class="submit-icon" @click="sendRequest" v-tooltip.top="'Send'" v-bind:class="{'disabled-icon':sendIconDisabled, 'dark-send-icon': darkMode}" >
        <font-awesome-icon class="send-icon"  :icon="['fas','location-arrow']" :style="{ color: darkMode ? '#ffffff' : '#0e59f6' }" size="2xl" /> 
      </div> -->
      <div class="submit-icon" @click="submitRequest" v-bind:class="{'disabled-icon':sendIconDisabled, 'dark-send-icon': darkMode}" >
        <!-- <font-awesome-icon class="send-icon"  :icon="['fas','location-arrow']" :style="{ color: darkMode ? '#ffffff' : '#0e59f6' }" size="2xl" />  -->
         <span v-if="!field.icon" >{{field.label}}</span> 
        <font-awesome-icon class="send-icon" v-else :icon="['fas',field.icon]"  :style="{ color: darkMode ? '#ffffff' : '#0e59f6' }" size="2xl" /> 
         
      </div>
      <!-- <textarea v-if=" 'type' in field && field['type']=='str' " v-on:keydown.enter="checkKeyPressSubmit" ref="projectQuery" spellcheck="true" :class="[ darkMode ? 'dark-query-input' : 'query-input', 'project-input']" v-model="text_input"  required></textarea> -->
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
//   import Spinner_2 from "./Spinner_2.vue";
  export default {
    name: "ButtonFormField",
    components: {
    //   Spinner_2,
    },
    props:{
        field:Object,
        field_name:String,
        darkMode:Boolean,
        sendIconDisabled:Boolean
    },

    data() {
      return {
        text_input:null,
      };
    },

      mounted() {
  },

    computed: {
      ...mapState({
      }),
    },

    methods: {
    submitRequest() {
      let message="Button Pressed"
      if('label' in this.field){
        message=this.field.label
      }
      else if ('tooltip' in this.field){
        message=this.field.tooltip
      }
      this.$emit("submitted",message);
    },
    },
  };
  </script>
  
  <style  lang="scss"  >
  @use "../../assets/styles/main.scss" ;
  </style>
  