<template>
    <div class="modal-backdrop" >
      <div :class="['modal', 'modal--groups-modal', darkMode ? 'darkModeTheme' : '']">
        <header class="modal-header">
          <h2>Forgot Password</h2>
          <!-- <h2>{{errorTitle}} {{darkMode}}</h2> -->
        <font-awesome-icon v-tooltip.top="'Close'" @click="close()" class="close-icon"  :icon="['fas','times']" /> 
        </header>
  
        <div class="modal-body">
          <div class="reset-body" v-if="!resetConfirmed">
            <span class="reset-instructions">Please enter your email address and we will send you an email with instructions to reset your password.</span>
            <div class="input-container">
                <input :class="[emailState==false?'input-error':'email-input']" type="text" v-model="email" placeholder="Email"
                       required>
                <!-- <button class="clear-button" @click="clearEmail" v-if="email"> <font-awesome-icon class="clear-button" :icon="['fas', 'times']" /></button> -->
                <font-awesome-icon @click="email=''" class="input-button" :icon="['fas', 'times']" v-if="email"/>
              </div>
        <Turnstile @tokenUpdated="(newToken)=>{updatedToken=newToken}" />
          </div>
          <div class="reset-body" v-if="resetConfirmed">
            <span class="reset-instructions">An email has been sent to validate your account.</span>
            <span class="reset-instructions">Please check your inbox, junk email, or promotions for the email.</span>
        </div>
        </div>
        <footer class="modal-footer">
          <!-- <button type="button" class="btn-apply" @click="apply">
            Apply Selection
          </button> -->
          <!-- <button
            type="button"
            class="btn-apply"
            @click="resetPassword();">
            Ok
          </button> -->

            <button v-if="!resetConfirmed" v-bind:class="{'inactive-button':!canReset}" class="btn-apply"
                    @click="sendResetPasswordEmail()">Reset Password
            </button>
            <button v-if="resetConfirmed"  class="btn-apply"
            @click="close();">OK
            </button>

        </footer>
      </div>
    </div>
  </template>
  
  <script>
  // import { mapState } from "vuex";
  import Turnstile from "@/components/Turnstile.vue";
  import {mapState} from "vuex";
   import global from "@/util/GlobalFunctions.js";


  export default {
    name: "ForgotPasswordModal",
    mixins: [global],
    props:{
      previousEmail:String
    },
    components: {
        Turnstile
    },
  
    mounted(){
        this.resetConfirmed=false;
        if(this.previousEmail)
        {
          this.$nextTick(()=>{this.email=this.previousEmail})
        }
    },
    data() {
      return {
        resetConfirmed: false,
        email:'',
        updatedToken: '',
      };
    },
    computed: {
      ...mapState({
        darkMode:(state)=>state.darkMode,
      }),
      canReset(){
        if (this.emailState == true && this.updatedToken != null && this.updatedToken != '' ){
          return true;
        }
        return false;
      },
      emailState() {
      if (this.email === "") {
        return null;
      } else if (!this.emailIsValid(this.email)) {
        return false;
      } else {
        return true;
      }
    },
    },
    methods: {
      close() {
        this.resetConfirmed=false;
        this.email='';
        this.updatedToken='';
        this.$emit("close");
      },
      sendResetPasswordEmail(){
        this.$store.dispatch('sendForgotPasswordEmail',{email:this.email})
        this.resetConfirmed=true;
      }
    },
  };
  </script>
  
  <style scoped lang="scss"  >
  @use "../styles/BasicModal.scss" ;
  @use "../styles/Buttons.scss" ;
  @use "../styles/ForgotPassword.scss" ;
  </style>
  