<template>
    <div class="bubble-container">
      <Bubble :data="chartData" :options="chartOptions" ref="chartRef" class="bubble-chart"/>
    </div>
  </template>
  
  <script>
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  PointElement,
  LinearScale
} from 'chart.js'
import { Bubble } from 'vue-chartjs'

ChartJS.register(LinearScale, PointElement, Tooltip, Legend)

  export default { 
    name:"BubbleChart",
    components: {
      Bubble  
    },
    props: {
        chartInfo:Object,
        refreshTime:Number,
        additionalPlugins:Object,
        commonChartOptions:Object
    },
    data() {
      return {
        chartData:{ datasets: [
    {
      label: 'Data One',
      backgroundColor: '#f87979',
      data: [
        {
          x: 20,
          y: 25,
          r: 5
        },
        {
          x: 40,
          y: 10,
          r: 10
        },
        {
          x: 30,
          y: 22,
          r: 30
        }
      ]
    },
    {
      label: 'Data Two',
      backgroundColor: '#7C8CF8',
      data: [
        {
          x: 10,
          y: 30,
          r: 15
        },
        {
          x: 20,
          y: 20,
          r: 10
        },
        {
          x: 15,
          y: 8,
          r: 30
        }
      ]
    }
  ]},
  chartOptions : {
  responsive: true,
  maintainAspectRatio: false,
  plugins:{
    ...this.additionalPlugins,
    title:{
      display:this.chartInfo?.name ? true:false,
      text:this.chartInfo.name,
      },
  },
  scales: {
          y: {
              title:{
                display:this.chartInfo?.labels?.y ? true:false,
                text:this.chartInfo.labels?.y??""
              },
              grid:this.commonChartOptions?.grid??{},
            },
            x:{
              ticks:{
              },
              title:{
                display:this.chartInfo?.labels?.x ? true:false,
                text:this.chartInfo.labels?.x??""
              },
              grid:this.commonChartOptions?.grid??{},
            }
      
        }

}
      };
    },
    mounted(){
    },
    computed: {

    },
    watch: {
     
    },
    methods: {
     
    }
  };
  </script>
  
  <style  lang="scss"  >
  @use "../../assets/styles/main.scss" ;
  .bubble-container{
      width: 100%;
      height:100%;
  }
  .bubble-chart{
    width: 100%;
    height: 100%;
  }
  </style>
  