<template>
<div class="custom-node-container" :class="highlightedClass" @mouseenter="highlightCheck=true" @mouseleave="highlightCheck=false">
<table>
    <thead>
        <tr>
            <th colspan="2">{{nodeData.data.label}}</th>
        </tr>
    </thead>
    <tbody v-if="nodeData.data.properties && Object.keys(nodeData.data.properties).length>0">
        <tr v-for="(value,key) in nodeData.data.properties" :key="key">
            <td>{{key}}</td>
            <td>{{value}}</td>
        </tr>
    </tbody> 
</table>
<Handle id="in" class="hidden-handle" v-if="horizontal!=null" type="target" :position="targetPosition" />
<Handle id="out" type="source" v-if="horizontal!=null" :position="sourcePosition" />

</div> 
</template>
    
    <script>
    import { Handle, Position } from '@vue-flow/core'

    export default {
      name: "CustomGraphNode",
      // props:defineProps<EdgeProps>(),
    components:{
        Handle,
    },
    computed:{
      sourcePosition(){
        return !this.horizontal ? Position.Right : Position.Bottom; 
      },
      targetPosition(){
        return !this.horizontal ? Position.Left : Position.Top;
      },
      highlightedClass(){
        if(this.connecting==='true' && this.targetNodes.includes(this.nodeData.id))
        {
          return 'highlighted';
        }
        if( this.connecting!='true' && this.highlightCheck==true && this.connecting!=null && this.connecting!=this.nodeData.id)
        {
          if(this.targetNodes.includes(this.nodeData.id))
          {
            return 'highlighted-red';
          }
          return 'highlighted';
        }
        return '';
      }
    },
      props:{
        nodeData:Object,
        horizontal:Boolean,
        connecting:String,
        targetNodes:Array,
      },
      mounted(){
      },
      data() {
        return {
          highlightCheck:false,
        };
      },
  
      methods: {

      },
  
    };
    </script>
    
    <style scoped lang="scss"  >
    table {
      min-width: 100px;
      min-height: 17px;
        border-collapse: collapse;
      }
      
      table th, table td {
        border: 1px solid black;
        padding: 4px;
        text-align: center;
        font-size: 10px;
      }
      
      table th {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
      }

      .hidden-handle
      {
        background-color: transparent;
        border-radius: 0;
        left: 0;
        top:0;
        transform: translate(0,0);
        height: 100%;
        width: 100%;
        z-index: -1;
        pointer-events: none;
      }
      .highlighted
      {
        border: solid blue 1px;
      }
      .highlighted-red
      {
        border: solid red 1px;
      }
    </style>
    