<template>
    <div class="sourcesComponent">
        <div v-if="sourcesExist" :class="darkMode ? 'dark-sources-list' : 'sources-list'" >
            <p><b>Sources:</b></p>
            <ul v-for="(source,index) in sourcesList" :key="index">
              <!-- <li><a :href="source" target="_blank">{{ source }}</a> </li> -->
              <li class="list-item" >
                <span v-html="formatSource(source,index)"></span>
                <span class="rate-item" v-if="showRateSources">
                  <UserFeedback :initial-feedback="getInitialFeedback(source)" @sendFeedback="(feedback) => updateSourceFeedback(source, feedback)" />
                </span>
              </li>
            </ul>
          </div>
          <button class="common-btn" @click="openRateSection" >Rate</button>
    </div>
    </template>
    
    <script>
    import { mapState } from "vuex";
    import UserFeedback from "../UserFeedback.vue";
    export default {
      name: "SourceComponent",
      components: {
        UserFeedback
      },
      props:{
        componentData: Object,
      },
      data() {
        return {
          showRateSources:false,
          passedData:{}
        };
      },
      
      mounted(){
        this.passedData=this.componentData
      },
      computed: {
        ...mapState({
          darkMode: state => state.darkMode
        }),
        sourcesExist()
        {
          if(this.sourcesList && this.sourcesList.length>0)
          {
            return true
          }
          return false
        },
        containsFormattedSources(){
          if('formatted_sources' in this.passedData.response_data)
            {
                return true;
            }
            else if(Array.isArray(this.passedData.response_data))
            {
                return false;
            }
            console.error('Sources in the SourceComponent are not formatted and not an array')
            return false
        },
        sourcesList(){
          if(this.passedData && this.passedData.response_data )
          {
            if(this.containsFormattedSources)
            {
                return this.passedData.response_data.formatted_sources; 
            }
            return this.passedData.response_data;
          }
          return [];
        }
      },
      watch:{

      },
      methods: { 
        getInitialFeedback(source)
        {
          if('source_feedback' in this.passedData.response_data && this.passedData.response_data.source_feedback && source in this.passedData.response_data.source_feedback)
          {
            return this.passedData.response_data.source_feedback[source];
          }
          return {}
        },
        updateSourceFeedback(source,feedbackObj){
          if(!('source_feedback' in this.passedData.response_data))
          {
            this.passedData.response_data.source_feedback={};
          }
          this.passedData.response_data.source_feedback[source]=feedbackObj;
          this.saveSources();
        },
        openRateSection(){
          this.showRateSources=!this.showRateSources;
        },
        copyComponent(){
            // return new ClipboardItem({
            // "text/plain": new Blob(['\n\nSources:\n'+this.sourcesList.join('\n')], { type: 'text/plain' })
            // });
            return '\n\nSources:\n'+this.sourcesList.join('\n');
        },
        saveSources(){
          const updatedObject={
            'custom_response_id':this.passedData.custom_response_id,
            "updated_data":this.passedData.response_data
          }
          this.$store.dispatch('updateCustomResponse',updatedObject).then((updateRes)=>{
        if(!(updateRes.status && updateRes.status=='success'))
        {
          console.error('Error: '+updateRes.message)
        } 
      })
        },
        formatSource(source,index){
          if(source.includes(": ") && source.split(": ").length>1 && (source.includes("http") || source.includes("www")))
          {
            let sourceList=source.split(": ")
            return `<span style="font-size:16px;">${sourceList[0]}:</span> <a  href="${sourceList[1]}" target="_blank" style="font-size:14px;" > ${sourceList[1]} </a>`
          }
          // else if((source.includes("http") || index.includes("http")&& typeof(index)=='string')){
          //   return source.includes("http")? `<a href="${source}" target="_blank"> ${index} </a>`: `<a href="${index}" target="_blank"> ${source} </a>`
          // }
          else if(typeof(index)=='string'&&(source.includes("http") || index.includes("http") )){
            return source.includes("http")? `<a href="${source}" target="_blank"> ${index} </a>`: `<a href="${index}" target="_blank"> ${source} </a>`
          }
          else if (typeof(index)=='string') {
            return `${index}: (${source})`
            // return `<a href="${source}" target="_blank"> ${source} </a>`
          }
          else{
            return `${source}`
          }
        },
      },
    };
    </script>
    
    <style scoped lang="scss"  >
    @use "../../assets/styles/main.scss" ;
    @use ".././styles/ChatEntry.scss" ;
    @use ".././styles/Buttons.scss" ;

    .sourcesComponent{
        height: 100%;
        width: 100%;
    }

    .list-item{
      display: flex;
      align-items: center;
    }
    .rate-item{
      height:100%;
      margin-left: auto;

    }
    .list-item::before {
      content: "•";
      margin-right: 10px;
    }

    .icon{
      border: solid red 1px !important;
      color: green;
    }

    .close-icon{
      border: solid blue 1px !important;
      margin-left: 2px;
      margin-right: 2px;
      padding-left: 1px;
      padding-right: 1px;
      cursor: pointer !important;
  }

  .rate-item{
    .icon{
      padding: 3px;
      margin:7px;
      font-size: 1.3rem;
      border-radius: 10px;
      color:grey;
      cursor: pointer;
      opacity: 0.4;
    }
  }



   :deep(.feedback-section){
    position: relative;
    margin-left: auto; /* Pushes this section to the right end */
    display: flex; /* To allow align-items to work if needed */
    justify-content: flex-end; /* Aligns content inside to the right */
    align-items: flex-end; /* Optional: aligns items vertically to the end */
    .icon{
    }
    .close-icon{
      color: grey;
      padding-right: 5px;
      cursor: pointer !important;
      position: relative;
    }
    /*? this can be removed if we want the feedback icon to show*/
    .feedback-icon{
      display: none;
    }
    .dark-icon{
      /*border: solid green 1px !important;*/
    }
    /*.icon{
      padding: 3px;
      margin:7px;
      font-size: 1.3rem;
      border-radius: 10px;
      color:grey;
      cursor: pointer;
      opacity: 0.4;
    }
    .close-icon{
        margin-left: 2px;
        margin-right: 2px;
        padding-left: 1px;
        padding-right: 1px;
        cursor: pointer !important;
        border: solid black 1px;
    }*/
  }



    </style>
    