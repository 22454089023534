<template>
  <div>
      <div class="record-backdrop"  v-bind:class="[isSelected ? (darkMode ? 'dark-request-selected' : 'request-selected') : '']" @click="selectRequest">
        <OptionMenu ref="option-menu" @deletingRecord="deletingRecord"  v-if="requestData.chat_session_id===currentMenuID" :requestData="requestData"   />
          <div class="record-entry" @contextmenu="rightClickOption($event)" >
              <div class="information-section">
              <!-- <span class="record-date">
                 {{ formatDateTime(requestData.start_time)}}</span> -->
              <span v-if="includeTitle" class="hidden-overflow record-text-section" ><b><br>Project: </b>{{requestData.user_input['project']}}</span>
              <div class="text-section" >
                  <span ref="textContent" id="text-area" v-bind:class="{'hidden-overflow record-text-section':expanded}" v-if="requestData.title || requestData.user_input['user_query']" class="record-text-section ">
                    <b v-if="includeTitle">Information: </b>
                    {{requestData.title || requestData.user_input['user_query']}}
                  </span>
              </div>
              </div>
          </div>
          <div class="loading-spinner" v-if="loading"><Spinner_2/></div>
          </div>
          </div>
    </template>
    
    <script>
    import { mapState } from "vuex";
    import Spinner_2 from "./Spinner_2.vue";
    import OptionMenu from "./OptionMenu.vue";
    export default {
      name: "HistoricRequestEntry",
      components: {
        Spinner_2,
        OptionMenu
      },
      props:{
          requestData:Object,
          selected:Object,
          openMenuID:String
      },
  
      data() {
        return {
          loading:false,
          expanded:true,
          overflowing:null,
        };
      },
      watch: {
        requestData()
        {
          setTimeout(()=>{
            this.checkOverflowing()
          },50)
        }
      },
  
        mounted() {
        this.checkOverflowing();
    },
  
      computed: {
        ...mapState({
          requestTypes:(state)=>state.requestTypes,
          darkMode:(state)=>state.darkMode,
          currentMenuID:(state)=>state.currentMenuID
        }),
        includeTitle(){
          if('queryData' in this.requestData && 'project' in this.requestData.queryData)
          {
            return true;
          }
          return false;
        },
        isSelected(){
          if(this.selected && this.selected.chat_session_id && this.selected.chat_session_id==this.requestData.chat_session_id)
          {
              return true;
          }
          return false;
        },
        requestText(){
          if(this.requestData.user_input)
          {
              return this.requestData.user_input;
          }
          else{
              return 'Error: Could not find user input';
          }
        },
        requestIcon(){
          if(!this.requestData.user_tool_id && this.requestData.user_tool_id!=0)
          {
              console.error('Could not find an appropriate request iocn or user tool')
              return;
          }
          // let found = this.requestTypes.find((req)=>req.value==this.requestData.user_tool_id)
              // switch (this.requestData.user_tool_id)
              // {
              //     case this.requestType['Email']:
              //     return ['fas','envelope'];
  
              //     case this.requestType['SocialMedia']:
              //     return ['fas','hashtag'];
  
  
              //     case this.requestType['BlogPost']:
              //     return ['fas','blog'];
  
                  // default:
              return this.requestTypes[this.requestData.user_tool_id].icon;
              // }
        },
  
  
      },
      methods: {
        deletingRecord(){
        },
        rightClickOption(event){
          this.$store.commit('setCurrentMenuID',this.requestData.chat_session_id)
          event.preventDefault();
        },
        checkOverflowing()
        {
          let contentElement = this.$refs.textContent;
        if (contentElement) {
          this.overflowing = contentElement.scrollWidth > contentElement.clientWidth;
        }
        else{
          setTimeout(()=>{
            this.checkOverflowing()
          },50)
        }
        },
  
      selectRequest(){
          this.$emit('selectRequest',this.requestData);
      },
      formatDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString);
      const options = { 
      //   weekday: 'long', 
        year: 'numeric', 
        month: 'numeric', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
      //   second: '2-digit' 
      };
      return dateTime.toLocaleDateString(undefined, options);
    },
      // expandText(event) {
      //     if(this.overflowing)
      //     {
      //         this.$store.commit('setCurrentMenuID',null)// Because the prop is stopped this will not close the menu so we will do it manually
      //         event.stopPropagation(); // Stop the propigation of the click to the upper section
      //         this.expanded = !this.expanded;
      //         this.$emit('expand')
      //     }
      // }
      },
    };
    </script>
    
    <style  lang="scss"  >
    @use "../assets/styles/main.scss" ;
    @use "./styles/HistoricRequestEntry.scss" ;
    </style>
    