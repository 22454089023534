<template>

  <div v-if="!sidebarActive" class="collapsed-sidebar">
    <font-awesome-icon  :class="{'dark-icon':darkMode==true}"  class="menu-icon" @click="sidebarActive = !sidebarActive" v-tooltip="'Expand'"
      :icon="['fas', 'bars']" />
  </div>
  <Transition name="slide-fade">
    <div v-if="sidebarActive" :class=" darkMode ? 'dark-side-bar' : 'side-bar'" class="side-bar">

      <div class="icon-section">
     
      
        <font-awesome-icon class="menu-icon" :class="{'dark-icon':darkMode==true}" v-tooltip="'Collapse'" :icon="['fas', 'bars']"
          @click="sidebarActive = !sidebarActive" />
        <font-awesome-icon class="new-icon" @click="newRequestSession" v-tooltip="'New\nSession'"
          :icon="['fas', 'plus-square']" />
      </div>
      <div class="scroll-section scroll scroll-1" @scroll="checkScroll" ref="infiniteList">
        <!-- <div :class="{
        'record-scroll': !darkMode,
        'dark-record-scroll': darkMode,
        'expanded-scroll': expandedIndex === index && !darkMode,
        'dark-expanded-scroll': expandedIndex === index && darkMode
      }"
        v-for="(request, index) in recordSessions" :key="index">
          <historic-request-entry
          :requestData="request"
          :selected="historicSelected"
          @selectRequest="recordSessionSelected"
          @expand=" () => handleExpand(index)"/>
        </div> -->
        <!-- <div :class="{
          'record-scroll': !darkMode,
          'dark-record-scroll': darkMode,
          'expanded-scroll': expandedIndex === index && !darkMode,
          'dark-expanded-scroll': expandedIndex === index && darkMode
        }"
          v-for="(record, index) in historicRecords" :key="index"> -->
          <div  v-for="(record, index) in historicRecords" :key="index">
          <div v-if="historicRecords[index].records.length > 0">
           <HistoricRequestGrouping :groupedRecordsData="historicRecords[index]" @expandItem="handleExpand" @historicRecordSelected="recordSessionSelected" :expandedIndex="expandedIndex" />
           </div>
          </div>
        <div class="loading-spinner" v-if="loading">
          <Spinner_2 />
        </div>
        <div class="loading-spinner" v-if="!loading && recordSessions.length == 0">
          <p>No Records</p>
        </div>
      </div>
    </div>
  </Transition>

<!-- :class=" ? true class : false classs" -->


</template>

  <script>
  import { mapState, mapMutations } from "vuex";
// import HistoricRequestEntry from './HistoricRequestEntry.vue';
  import HistoricRequestGrouping from "./HistoricRequestGrouping.vue";
  import Spinner_2 from "./Spinner_2.vue";

  export default {
    name: "HistoricalRequests",
    components: {
      Spinner_2,
      // HistoricRequestEntry,
      HistoricRequestGrouping,
    },
    emits: ["historicRecordChosen","historicRecordSelected","expandItem"],
    props:{
      showSideBar:Boolean,
    },
    created(){
      if(this.recordSessions.length==0)
      {
        this.getRecords(15);
      }
    },
    data() {
      return {
        sidebarActive:true,
        loading:false,
        canLoad:true,
        expandedIndex: null
      };
    },
    watch: {
      addedRecord(){
        if(this.addedRecord!=null && this.addedRecord!=undefined)
        {
          let found = this.recordSessions.find((record)=> record.chat_session_id==this.addedRecord);
          if(found)
          {
            this.historicSelected=found;
          }
        }
      },
      showSideBar(){
        if(this.showSideBar==true)
      {
        this.sidebarActive=true
      }
      else{
        this.sidebarActive=false
      }
      },
      recordSessions(newSessions,oldSessions){
        if(newSessions.length<oldSessions.length && this.loggedIn)
          {
            this.checkScroll();
          }
        }
    },
    computed: {
      ...mapState({
        recordSessions:(state)=>state.recordSessions,
        recordSessionOffset:(state)=>state.recordSessionOffset,
        addedRecord:(state)=>state.addedRecord,
        historicSelected:(state)=>state.historicSelected,
        darkMode:(state)=>state.darkMode,
        loggedIn:(state)=>state.loggedIn,
      }),
      historicSelected: {
      get() {
        return this.$store.state.historicSelected;
      },
      set(value) {
        this.setHistoricSelected(value);
      },
    },
    historicRecords(){
      const today = new Date();
      const todayCompareTime = today.getTime() - 86400000;
      const yesterday = today.getTime() - 172800000; // 86400000 = 2 day in milliseconds
      const lastSevenDays = today.getTime() - 604800000; // 604800000 = 7 days in milliseconds
      const lastThirtyDays = today.getTime() - 2592000000; // 2592000000 = 30 days in milliseconds

      const buckets = {
        today: {records:[],name:'Today'},
        yesterday: {records:[],name:'Yesterday'},
        lastSeven: {records:[],name:'Last 7 Days'},
        lastThirty: {records:[],name:'Last 30 Days'},
        older: {records:[],name:'Older'},
      };
        for(var record in this.recordSessions)
        {
        let recordDate= new Date(this.recordSessions[record]?.start_time)
          if(recordDate.getTime() > todayCompareTime )
          {
            buckets.today.records.push(this.recordSessions[record])
          }
          else if(recordDate.getTime()>= yesterday)
          {
            buckets.yesterday.records.push(this.recordSessions[record])
          }
          else if(recordDate.getTime()>= lastSevenDays)
          {
            buckets.lastSeven.records.push(this.recordSessions[record])
          }
          else if(recordDate.getTime()>= lastThirtyDays)
          {
            buckets.lastThirty.records.push(this.recordSessions[record])
          }
          else{
            buckets.older.records.push(this.recordSessions[record])
          }
        }
        return buckets
      }
    },
    methods: {
      
        ...mapMutations({
          setHistoricSelected: 'setHistoricSelected'
        }),
      newRequestSession(){
        this.setHistoricSelected({});
        this.$store.dispatch("startNewSession")
      },
      recordSessionSelected(requestSelected){
        this.historicSelected=requestSelected;
        this.$emit('historicRecordSelected',requestSelected);
      },
      getRecords(fetch=5){
        if(this.loading==true)
        {
          return
        }
        if(this.recordSessionOffset>this.recordSessions.length)
        {
          console.warn('We have hit the max value and do not want to load anymore')
          this.loading=false;
          return;
        }
        this.loading=true;
        this.$store.dispatch('getRecordSessions',{fetch:fetch}).then((rec)=>{
          if(rec)
          {
            this.loading=false;
            this.checkScroll()
          }
        }).catch((err)=>{
          this.loading=false;
          console.error('Error while trying to get records with the err',err)
        })
      },
      reset(){
        this.$store.dispatch('resetState');
      },
        returnRecordInfo(){

        },
        checkScroll()
        {
            this.$nextTick(()=>{
           let scrollWindow=this.$refs.infiniteList;
            if(scrollWindow.scrollTop + scrollWindow.clientHeight >= scrollWindow.scrollHeight && !this.loading)
            {
                scrollWindow.scrollTop==0 && scrollWindow.clientHeight==scrollWindow.scrollHeight?this.getRecords(10) :this.getRecords() //? if the screen is not full load with more than ususal records
            }
            })
        },
        handleExpand(index) {
      if (this.expandedIndex === index) {
        this.expandedIndex = null; // Collapse if already expanded
      } else {
        this.expandedIndex = index; // Expand the selected entry
      }
    },

    },
  };
  </script>

  <style  lang="scss"  >
  @use "../assets/styles/main.scss" ;
  @use "./styles/HistoricalRequests.scss" ;
  </style>
