<template>
  <div class="userRequestChat">
  <div  :class="darkMode ? 'dark-userRequest' : 'userRequest'" >  
          <div class="information-section">
          <div v-if="hasProject"><b>Project:</b> {{requestData.queryData.project}}</div>
          <span class="UserRequestText" ><b v-if="hasProject">Information:</b> {{requestData.displayText||requestData.userQuery}}</span>
          </div>
          <div class="loading-spinner" v-if="loading"><Spinner_2/></div>
          </div>
          <div class="user-icon">
            <font-awesome-icon icon="fa-solid fa-comment-dots" />
              
          </div>
  </div>
   
  </template>
  
  <script>
  import { mapState } from "vuex";
  import Spinner_2 from "./Spinner_2.vue";
  export default {
    name: "UserRequest",
    components: {
      Spinner_2,
    },
    props:{
        requestData:Object,
    },

    data() {
      return {
        loading:false,
      };
    },
    watch: {
      
    },


    computed: {
      ...mapState({
        darkMode:(state)=>state.darkMode
      }),
      isSelected(){
        if(this.selected && this.selected.record_id && this.selected.record_id==this.requestData.record_id)
        {
            return true;
        }
        return false;
      },
      hasProject(){
        if('queryData' in this.requestData && this.requestData.queryData && 'project' in this.requestData.queryData){
          return true;
        }
        return false;
      },
    },
    methods: {
 
    },
  };
  </script>
  
  <style  lang="scss"  >
  @use "../assets/styles/main.scss" ;
  @use "./styles/ChatEntry.scss" ;
  </style>
  