<template>
    <div id="ModelRequest" >
      <!-- <div id="sideBar"> -->
      <Header v-if="true" />
      <div :class="['scroll scroll-1', darkMode ? 'dark-body-section' : 'body-section']" >
        <HistoricalRequests :showSideBar="showSidebarByDefault" ref="historic" @historicRecordSelected="historicRecordChosen" />
        <div class="screen-container" >
            <component v-if="!loading" :is="currentPage" ref="currentPage" />
            <div v-if="loading" class="loading-spinner"><Spinner_2/></div>
        </div>
      </div>
    </div>
  </template> 
    <script>
    import {mapState} from 'vuex';
  import HistoricalRequests from "../components/HistoricalRequests.vue";
  import Header from '../components/Header.vue'; 
  // import CustomDropdown from '../components/CustomDropdown.vue';      
  // import UserRequest from '../components/UserRequest.vue';
  // import ModelResponse from '../components/ModelResponse.vue';  
  import { closeableDirective } from '@/composables/ClickOutside';
  import model_request from '../pages/ModelRequest.vue';
  import chart_display from '../pages/ChartsPage.vue';
  import Spinner_2 from '@/components/Spinner_2.vue';
    export default { 
      name: "ModelRequest",
      components: {
        HistoricalRequests,
        Header, 
        model_request,
        chart_display,
        Spinner_2
        // CustomDropdown,
        // UserRequest,
        // ModelResponse,
      },
      data() {
        return {
          loading:false,
          sidebarDisabledPages:['charts'],
        };
      }, 
      mounted(){
  

      },
      directives: {
      closeable: closeableDirective // Register the directive
    },
      computed:{
        ...mapState({
          loggedIn: (state) =>state.loggedIn, 
          chatTopic: (state)=>state.chatTopic,
          selectedTopicTool: (state)=>state.selectedTopicTool,
          chatTypeSelected: (state)=>state.chatTypeSelected,
          darkMode: (state)=>state.darkMode
        }),
        currentPage() {
            if(this.chatTypeSelected && 'display_type' in this.chatTypeSelected)
            {
                switch(this.chatTypeSelected.display_type){
                    case 'chat':
                        return model_request
                    case 'charts':
                        return chart_display
                    default:
                        return model_request
                }
            }
            else{
                return model_request
            }
        },
        showSidebarByDefault() {
          if(this.chatTypeSelected && 'display_type' in this.chatTypeSelected && this.sidebarDisabledPages.includes(this.chatTypeSelected.display_type))
        {
          return false;
        }
        return true;
        },
        },
      // Please reference store/readme.md file for all API calls
      watch:{
      },
      methods: {


        historicRecordChosen(historicRecord)
        {
          this.loading=true
          this.$store.dispatch('getSessionRecords',historicRecord.chat_session_id).then((records)=>{
            this.loading=false
            this.$nextTick(()=>{
              if('historicRecordChosen' in this.$refs.currentPage)
              {
                this.$refs.currentPage.historicRecordChosen(records)
              }
            })

          })
       
        },

      },
    };
    </script>
    
    <style lang="scss" >
    @use "../assets/styles/main.scss" ;
    @use "./styles/MainPage.scss" ;
    </style>
    