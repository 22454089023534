<template>
  <vue-turnstile :site-key="turnstileKey" :theme="'dark'" :auto-reset="true" @verified="token = $event" @error="onErrorTurnstile" style="margin: 1em"/>
  </template>
  
  <script>
  import VueTurnstile from '@/components/gaviti/vue-turnstile/dist/vue-turnstile';
  import {mapState} from "vuex";
  
  export default {
    name: "Turnstile",
    mixins: [global],
    components: {
      VueTurnstile
    },
    data() {
      return {
        isProd: process.env.NODE_ENV === 'production',
        auth: "",
        token: null,
      };
    },
    watch: {
        token: function () {
            this.$emit('tokenUpdated', this.token);
        }
    },
    computed: {
      updatedToken() {
        return this.token;
      },
      turnstileKey() {
        if (this.isProd === true) {
          return process.env.VUE_APP_PRODUCTION_TURNSTILE_KEY + '';
        }
        return process.env.VUE_APP_TEST_TURNSTILE_KEY + '';
      },
      ...mapState({
        darkMode:(state)=>state.darkMode,
      }),
    },
    mounted: function () {

    },
    methods: {
      onErrorTurnstile(code) {
        console.error('There was an error with the turnstile. The code is:', code);
        this.token='';
      },
      onUnsupportedTurnstile() {
        console.error('The browser does not support turnstile.');
        //todo See if we need these to work or not 
      },
      onExpiredTurnstile() {
        console.info('The turnstile has expired.');
        VueTurnstile.reset();
        this.token='';
      }
    },
  };
  </script>
  
  <style scoped lang="scss" >
  @use "../assets/styles/main.scss" ;
  </style>
  