// CustomTooltip.js

let showTooltip;
let hideTooltip;

export const CustomTooltip = {
    mounted(el, binding) {
      const tooltipText = binding.value;
  
      // Define functions to show and hide tooltip
       let timer;
       showTooltip=() => {
        if (tooltipText=="" || tooltipText==null)
          {
            return
          }

        timer = setTimeout(() => {
          const tooltipElement = document.createElement('div');
          tooltipElement.className = 'custom-tooltip';
          tooltipElement.textContent = tooltipText;

          // Append tooltip to the body
          document.body.appendChild(tooltipElement);
          // Calculate position of the tooltip
          const rect = el.getBoundingClientRect();
          const top = binding.modifiers.top? rect.top-tooltipElement.offsetHeight-2 : rect.top + tooltipElement.offsetHeight +1;
          const left = rect.left - ( tooltipElement.offsetWidth/2) + (rect.width/2) ;
          
          // Set tooltip position
          tooltipElement.style.top = `${top}px`;
          tooltipElement.style.left = `${left}px`;
        }, 600);
      }
      
  
      hideTooltip=() => {
        // Remove the tooltip element
        clearTimeout(timer);
        const tooltipElements = document.querySelectorAll('.custom-tooltip');
        tooltipElements.forEach((tooltipElement) => {
          document.body.removeChild(tooltipElement);
        });
      }
  
      // Add event listener for mouseenter
      el.addEventListener('mouseenter', showTooltip);
  
      // Add event listener for mouseleave
      el.addEventListener('mouseleave', hideTooltip);
    },
    updated(el,binding) {
        if(binding.value==binding.oldValue)
        {
            return;
        }
        const tooltipElement = document.querySelector('.custom-tooltip');
        if (tooltipElement) {
            tooltipElement.textContent=binding.value;
            // Calculate position of the tooltip
        const rect = el.getBoundingClientRect();
        const top = binding.modifiers.top? rect.top-tooltipElement.offsetHeight-2 : rect.top + tooltipElement.offsetHeight +1;
        
        // const top = rect.top + tooltipElement.offsetHeight + 2;
        const left = rect.left - ( tooltipElement.offsetWidth/2) + (rect.width/2) ;
        
        // Set tooltip position
        tooltipElement.style.top = `${top}px`;
        tooltipElement.style.left = `${left}px`;
        }
        // Update tooltip content if value changes
      },
    unmounted(el) {
      // Remove event listeners when the component is unmounted
      hideTooltip();
      el.removeEventListener('mouseenter', showTooltip);
      el.removeEventListener('mouseleave', hideTooltip);
    }
  };
  