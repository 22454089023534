<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div id="app" :class="['mode', darkMode ? 'darkModeTheme' : '']">
    <!-- <div id="header" v-if="currentRoute!='/'" > -->
      <div id="header" v-if="false"  >
      Test Header
      {{ currentRoute }}
    </div>
   <!-- <div v-if="loggedIn">
    <KeepAlive class="alive">
      <router-view></router-view>
    </keep-alive>
    </div>
    <div v-if="!loggedIn">
    <router-view class="alive"></router-view>
    </div> -->
    <div id="app-body" :style="[darkMode ? 'filter: invert(1) hue-rotate(180deg);' : '']" >
      <LogoutPromptModal
      v-show="showLogoutModal"
      ref="logoutModal"
      />
    <ErrorMessageModal
    v-if="showLoginError"
    errorTitle="Login Error"
    errorBody="Your login session seems to have expired. Please log in again."
    @close="closeErrorModal"
    />
   <router-view v-slot="{ Component, route }">
    <template v-if="route.path!=='/'">
      <keep-alive>
        <component :is="Component"  />
      </keep-alive>
   </template>
  <template v-else>
       <component :is="Component"  />
  </template>
      <!-- <keep-alive :include="includedComponents(route,Component)" >
        <component :is="Component" />
      </keep-alive> -->
    </router-view>
    </div>
    </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import { mapState } from 'vuex';
import ErrorMessageModal from './components/modals/ErrorMessage.vue';
import LogoutPromptModal from './components/modals/LogoutPrompt.vue';



export default {
  name: 'App',
  components: {
    // HelloWorld
    ErrorMessageModal,
    LogoutPromptModal,

  },
  data() {
    return {
      showLogoutModal:false,
    };
  },
  computed:{
    ...mapState({
    loggedIn:(state)=>state.loggedIn,
    signedIn:(state)=>state.signedIn,
    showLoginError:(state)=>state.showLoginError,
    loggingOut:(state)=>state.loggingOut,
    routeHistory:(state)=>state.routerHistory,
    darkMode:(state)=>state.darkMode,
  }),
  currentRoute(){
    return this.$route.path;
  }
},
  created: function () {
    },
    mounted: function()
    {
      // toggle dark mode on based on system preference, otherwise do nothing (leave it light mode)
      window.matchMedia("(prefers-color-scheme: dark)").matches === true? this.$store.dispatch('setDarkMode',true) : this.$store.dispatch('setDarkMode',false);
    },
methods:{
  closeErrorModal(){
    this.$store.dispatch("logUserOut");
    this.$store.dispatch("showLoggedOutMessage",false)
},
    includedComponents(route) {
      // Include only the current route component
      return route.path === '/' ? [] : '*';
    },
    excludedComponents(route) {
      // Exclude all components except the current route component
      return route.path === '/' ? '*' : [];
    },
showLogout(){
  return new Promise((resolve) => {
    if(this.loggingOut==true)
    {
      resolve(true);
      return
    }
    const confirmHandler = () => {
          this.showLogoutModal=false;
          this.$store.dispatch('logUserOut');
        };
        const cancelHandler = () => {
          resolve(false);
          this.showLogoutModal=false;
        };
        if(this.$refs.logoutModal){
          this.showLogoutModal = true;
          this.$refs.logoutModal.confirmLogoutCallback = confirmHandler;
          this.$refs.logoutModal.cancelLogoutCallback = cancelHandler;
        }

  });
},

},
};
</script>

<style lang="scss"   >
@use "./App.scss" ;
</style>
