// import Vue from "vue";
// import VueCookies from "vue-cookies";
import {createStore} from "vuex";
import * as Parser from "../util/ObjectParse";
import router from "../util/Routes.js";
import {
  RequestJsonResponse,
  UrlParameterRequestJsonResponse,
  callLogin,
  PUTRequestFormInputJsonResponse
} from "../util/apiCalls";
import createPersistedState from 'vuex-persistedstate';


Parser;

function getDefaultState(){
  return{
    authToken:"",
    loggedIn:false,
    showLoginError:false,
    recordSessions:[],
    recordSessionOffset:0,
    loggingOut:false,
    routerHistory:[],
    addedRecord:null,
    largeModel:true,
    chatTopicId:"ff255948-922b-4afa-a3d8-9beccaeeabdc",
    newSession:true,
    modelConversation:[],
    historicSelected:{},
    userTools:{},
    chatTypeSelected:{},
    chatTopic:{},
    darkMode:false,
    selectedTopicTool:{},
    userPreferences:{},
    currentMenuID:null,
  }
}


const store = createStore({
  state:getDefaultState, 
  //////////////////////////////!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!   LOOK AT IF WE WANT THIS IS PLACE FOR THE FULL APP 
  plugins:[
    // createPersistedState({
    //   getState:(key)=>Cookies.getJSON(key),
    //   setState:(key)=> Cookies.set(key,this.Store.state,{expires:3,secure:true})
    // })
    createPersistedState(),
  ],
  mutations: {
    reset(state){
      const s = getDefaultState();
      let hist=state.routerHistory; //? this is done to maintane the history across resets
      Object.keys(s).forEach(key=>{
        state[key]=s[key];
      });
      state.routerHistory=hist;
    },
    setAuthToken(state,token){
      state.authToken=token;
    },
    setAddedRecord(state,record){
      state.addedRecord=record;
    },
    setHistoricSelected(state,histSelect){
      state.historicSelected=histSelect
    },
    setModelConversation(state,conv){
      state.modelConversation=conv
    },
    setNewSession(state,newsession){
      state.newSession=newsession;
    },
    setLoggedIn(state, logged) {
      state.loggedIn = logged;
    },
    setShowLoginError(state,show)
    {
      state.showLoginError=show;
    },
    setUserTools(state,tools){
      state.userTools=tools
    },
    setChatTypeSelected(state,type){
      state.chatTypeSelected=type;
    },
    addRecordSessions(state,sessions){
      state.recordSessions=state.recordSessions.concat(sessions);
    },
    updateSelectedTopicTool(state,tool)
    {
      state.selectedTopicTool=tool
    },
    setCurrentMenuID(state,menuID)
    {
      state.currentMenuID=menuID
    },
    insertRecordSession(state,requestInfo)
    {
      try{
      if(JSON.stringify(requestInfo.request)=== JSON.stringify(state.recordSessions[requestInfo.position]))
      {
        return;
      }
    }
    catch(e){
      console.error('error while trying to see if the model responses are equal',e)
    }
      if(requestInfo.request.chat_session_id)
      {
        state.addedRecord=requestInfo.request.chat_session_id;
      }
      state.recordSessions.splice(requestInfo.position,0,requestInfo.request)
    },
    resetAddedRecord(state)
    {
      state.addedRecord=null;
    },
    updateRecordSessionOffset(state,addition)
    {
      state.recordSessionOffset+=addition;
    },
    setLoggingOut(state,logging)
    {
      state.loggingOut=logging;
    },
    setLargeModel(state,large){
      state.largeModel=large;
    },
    updateChatTopic(state, topic) {
        state.chatTopic=topic;
    },
    toggleDarkMode(state){
      state.darkMode = !state.darkMode
    },
    setDarkMode(state, value){
      state.darkMode = value
    },
    setUserPreferences(state,preferences)
    {
      state.userPreferences=preferences
      if('dark_mode' in preferences)
      {
        state.darkMode=preferences.dark_mode
      }
    },
    removeFromRecordSessions(state,id){
      state.recordSessions=state.recordSessions.filter(session=>{
        return session.chat_session_id!=id
      })
      state.recordSessionOffset-=1;// This will make the offset 1 less to make sure we can still get the right order
    }
  },
  actions: {
    checkResetSession({commit,dispatch}){
      if(this.state.newSession==true)
        {
          commit('setNewSession',false)
          dispatch('getMostRecentRequest')
        }
    },
    resetState({commit}){
      commit('reset');
    },
    //?This function is called by many others and will return the user to the login page and reset all of the current values
    // How is this different from callLogoutAPI
    async loggedOut({ commit, dispatch }) {

      if(this.state.loginErrorShown==false)
      {
        dispatch('callLogOutAPI');
        alert("You are logged out.\nPlease log in again")//this will pop up the box and have it go back to the main page 
        router.replace({name:'Login'})//push back to the login screen 
        if(dispatch('isLocalStorageAvailable'))
        {
          localStorage.removeItem('savedToken');
        }
        commit('reset');
        commit('setErrorShown',true);
      }
      console.warn("You are logged out please log in again to get data");
    },

   async startNewSession({commit}){
    commit("setNewSession",true); 
    commit('setModelConversation',[])
   },


   async initializeServer({commit, dispatch}){
    return new Promise((resolve)=>{
      UrlParameterRequestJsonResponse({},'/user/tools').then((resp)=>{
        if(resp.status=='success')
        {
          commit('setUserTools',resp.results)
          dispatch('getUserPreferences').then(()=>{
            resolve(true)
          })
        }
        else{
          console.error("Cannot find the user tools with the resp",resp)
          resolve(false)
        }
      })
    })
   },




    async serverLogin({ commit,dispatch }, loginInfo) {
      return new Promise((resolve,reject) => {
        callLogin(loginInfo).then((res) => {
          if(res.status == 'success')
          {
            dispatch('initializeServer').then((initialized)=>{
              if(initialized){
                if(dispatch('isLocalStorageAvailable') && 'access_token' in res.results)
                {
                  localStorage.setItem('savedToken',res.results.access_token);
                }
                commit('setLoggedIn',true);
                resolve(res);
              }
              else{
                commit('setLoggedIn',false);
                resolve({"status":'failed',"message":"Could not find user permissions"})
              }
              //? this is how we can call another function 
             })
          }
          else{
            commit('setLoggedIn',false);
            resolve(res)
          }
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          console.error('Error: could not sign into server with the message: ',err)
          reject(err)
        });
      });
    },

    async getUserPreferences({commit}){
      return new Promise((resolve)=>{
        UrlParameterRequestJsonResponse({},'/user/preferences').then((resp)=>{
          if(resp.status=='success')
          {
            commit('setUserPreferences',resp.results)
            resolve(true)
          }
          else{
            console.error("Cannot find the user tools with the resp",resp)
            resolve(false)
          }
        })
      })
    },

    async getModelResponse({ commit, dispatch }, requestInfo) {
      return new Promise((resolve,reject) => {
        requestInfo.large_model=this.state.largeModel;
        requestInfo.new_session=this.state.newSession;
        //! THIS CAN CHANGE WHEN THE SERVER IS UPDATED
        // if(!('user_query' in requestInfo))
        // {
        //   requestInfo.user_query='';
        // }
        let endpoint="/chat"
        if (this.state.selectedTopicTool && this.state.selectedTopicTool.endpoint){
          endpoint=this.state.selectedTopicTool.endpoint
        }
        let requestFunction= RequestJsonResponse;
        if (this.state.selectedTopicTool && this.state.selectedTopicTool.request_type){
          switch (this.state.selectedTopicTool.request_type) {
            case "POST":
              requestFunction= RequestJsonResponse;
              break;
            case "GET":
              requestFunction= UrlParameterRequestJsonResponse;
              break;
          }
        }
        requestFunction(requestInfo,endpoint).then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            if(res.results && 'model_request_id' in res.results) //? this is to check if it has a response as some like the test get data will not be saved in the records
            {
              dispatch('checkResetSession');
            }
          }
          else{
            reject(parseErrorReturn('Error: Unable to get an email response',res))
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get an email response',err))
        });
      });
    },


    updateUserPreferences({commit},userPreferences){
      return new Promise((resolve)=>{
        RequestJsonResponse(userPreferences,'/user/preferences','PATCH').then((resp)=>{
          if(resp.status=='success')
          {
            commit('setLoggedIn',true);
            resolve(true)
          }
          else{
            resolve(false)
          }
        })
      })
    },

    async deleteRecordSession({commit},chatSessionID){
      return new Promise((resolve,reject)=>{
        let delParams={
          chat_session_id:chatSessionID,
        }
        UrlParameterRequestJsonResponse(delParams,"/records/record_session",'DELETE').then((resp)=>{
          if(resp.status=='success')
          {
            commit('setLoggedIn',true);
            commit('removeFromRecordSessions',chatSessionID);
            resolve(true)
          }
          else{
            console.error('Error: Unable to delete response',resp)
            reject(parseErrorReturn('Error: Unable to delete response',resp))
            resolve(false)
          }
        })
      })
    },

    async getEmailResponse({ commit, dispatch }, emailInfo) {
      return new Promise((resolve,reject) => {
        emailInfo.large_model=this.state.largeModel;
        emailInfo.new_session=this.state.newSession;
        emailInfo.chat_topic_id=this.state.chatTopicId;
        RequestJsonResponse(emailInfo,"/model_request/email").then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            dispatch('checkResetSession');
          }
          else{
            reject(parseErrorReturn('Error: Unable to get an email response',res))
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get an email response',err))
        });
      });
    },


    async getSocialMediaResponse({ commit, dispatch }, queryInfo) {
      return new Promise((resolve,reject) => {
        queryInfo.large_model=this.state.largeModel;
        queryInfo.new_session=this.state.newSession;
        queryInfo.chat_topic_id=this.state.chatTopicId;
        RequestJsonResponse(queryInfo,"/model_request/social_media").then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            dispatch('checkResetSession');
          }
          else{
            reject(parseErrorReturn('Error: Unable to get a social media response',res))
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get a social media response',err))
        });
      });
    },

    async getBlogPostResponse({ commit, dispatch }, queryInfo) {
      return new Promise((resolve,reject) => {
        queryInfo.large_model=this.state.largeModel;
        queryInfo.new_session=this.state.newSession;
        queryInfo.chat_topic_id=this.state.chatTopicId;
        RequestJsonResponse(queryInfo,"/model_request/blog_post").then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            dispatch('checkResetSession');
          }
          else{
            reject(parseErrorReturn('Error: Unable to get a blog post response',res))
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get a blog post response',err))
        });
      });
    },

    async getRagWithDocsResponse({ commit, dispatch }, queryInfo) {
      return new Promise((resolve,reject) => {
        let path='/rag_with_docs'
        if(queryInfo.agent==true)
          {
            path='/rag_with_docs1'
          }
          queryInfo={user_query:queryInfo.user_query,
            new_session:false,
            chat_topic_id:"0bcb40ac-8daf-475a-8c25-650ab5d45fd7", //? this is a placeholder and will need to be swapped with an actual value when we have all this in 
            large_model:this.state.largeModel
          }
        RequestJsonResponse(queryInfo,path).then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            dispatch('checkResetSession');
          }
          else{
            reject(parseErrorReturn('Error: Unable to get a blog post response',res))
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get a blog post response',err))
        });
      });
    },

    async uploadDocuments({commit},documents){
      return new Promise((resolve,reject) => {
        commit('setLoggedIn',true);
        resolve('Not done yet but had the right idea')
        reject('hi')
        PUTRequestFormInputJsonResponse(documents,"/document/upload").then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            resolve(res)
          }
          else{
            reject(parseErrorReturn('Error: Unable to get a blog post response',res))
          }
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get a blog post response',err))
        });
      });
    },

    async getWebChatResponse({ commit, dispatch }, queryInfo) {
      return new Promise((resolve,reject) => {
        let path='/web_search'
        if(queryInfo.agent && queryInfo.agent==true)
          {
            path="/web_search_with_function_calls"
          }
          queryInfo={user_query:queryInfo.user_query,
            new_session:false,
            chat_topic_id:"0bcb40ac-8daf-475a-8c25-650ab5d45fd7", //? this is a placeholder and will need to be swapped with an actual value when we have all this in 
            large_model:this.state.largeModel
          }
        RequestJsonResponse(queryInfo,path).then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            dispatch('checkResetSession');
          }
          else{
            reject(parseErrorReturn('Error: Unable to get a blog post response',res))
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get a blog post response',err))
        });
      });
    },

    async getMostRecentRequest({ commit }) {
      return new Promise((resolve,reject) => {
        let requestInfo={
          fetch:1,
          offset:0,
        }
        UrlParameterRequestJsonResponse(requestInfo,"/records/record_sessions").then((res) => {
          if(res.status == 'success' && res.results && res.results.length==1 )
          {
            commit('insertRecordSession',{position:0,request:res.results[0]}) 
            commit('updateRecordSessionOffset',requestInfo.fetch)
          }
          else{
            reject(parseErrorReturn('Error: Unable to get historic request',res))
          }
          resolve(true)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get an email response',err))
        });
      });
    },


    async getRecordSessions({ commit }, requestInfo) {
      return new Promise((resolve,reject) => {
          requestInfo.offset=this.state.recordSessionOffset;
        UrlParameterRequestJsonResponse(requestInfo,"/records/record_sessions").then((res) => {
          if(res.status == 'success' && res.results)
          {

            commit('addRecordSessions',res.results);
            commit('updateRecordSessionOffset',requestInfo.fetch)
          }
          else{
            reject(parseErrorReturn('Error: Unable to get historic requests',res))
          }
          resolve(true)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get an email response',err))
        });
      });
    },
    
    async updateCustomResponse({commit},customResponse){
      return new Promise((resolve)=>{
        RequestJsonResponse(customResponse,'/records/custom_response',"PATCH").then((res) => {
          if(res.status != 'success')
            {
              console.error(parseErrorReturn('Error: Unable to get a blog post response',res))
              resolve(false)
            }
            else{
              commit('setLoggedIn',true);
            }
            resolve(res)
        }).catch((err)=>{
          console.error('Error: Unable to get a blog post response',err)
          //? this one specifically the error does not matter so the message or a custom message would be enough
        })
      })
     },

    async responseFeedback({commit},feedbackObj){
      return new Promise((resolve,reject) => {
        commit
        RequestJsonResponse(feedbackObj,'/records/feedback',"PUT").then((res) => {
          if(res.status != 'success')
          {
            console.error(parseErrorReturn('Error: Unable to get a blog post response',res))
            reject(parseErrorReturn('Error: Unable to get a blog post response',res))
          }
          resolve(res)
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get a blog post response',err))
        });
      });
    },

    updateLargeModel({commit},largeChecked){
      commit('setLargeModel',largeChecked);
    },

    async setHistoricSelectedOptions({commit},selectedOptions){
      if(!selectedOptions || selectedOptions.length==0)
      {
        console.error('The records could not be added')
        return;
      }
      var lastRecord=selectedOptions[selectedOptions.length-1];


// Assuming you can map directly by `topic_tool_id`
    let foundTool = null;

    for (const chatType of Object.values(this.state.userTools)) {
      for (const topic of Object.values(chatType.topics || {})) {
        foundTool = Object.values(topic.user_tools || {}).find(tool => tool.topic_tool_id === lastRecord.topic_tool_id);
        if (foundTool) {
          commit('setChatTypeSelected', chatType);
          commit('updateChatTopic', topic);
          commit('updateSelectedTopicTool', foundTool);
          return; // Found and committed, exit the function
        }
      }
    }
    console.error('We where not able to find the applicable tool for the last record. It is possible the chat topic tool has been deleted',lastRecord)
    },

    async getSessionRecords({ commit, dispatch }, chatSessionID) {
      return new Promise((resolve,reject) => {
        commit('resetAddedRecord');
        commit('setNewSession',false); 
        UrlParameterRequestJsonResponse({chat_session_id:chatSessionID},"/records/session_records").then((res) => {
          if(res.status == 'success' && res.results)
          {
            dispatch('setHistoricSelectedOptions',res.results);
            resolve(res.results);
          }
          else{
            reject(parseErrorReturn('Error: Unable to get session records',res))
          }
          resolve(true)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get an email response',err))
        });
      });
    },

    isLocalStorageAvailable(){
      var test='testing the store';
      try{
        localStorage.setItem('test',test);        
        localStorage.removeItem('test');
        return true;
      }
      catch(e){
        return false;
      }
    },

    async checkUserLoggedIn({commit,dispatch})
    {
      return new Promise((resolve,reject) => {
        if(!dispatch('isLocalStorageAvailable')){
          resolve(false);
          reject('Error: Local storage not available');
          return;
        }
        var savedToken = localStorage.getItem('savedToken');
        if(!savedToken)
        {
          resolve(false);
          reject('Error: NO AUTH TOKEN');
          return;
        }
        commit('setAuthToken',savedToken);
        UrlParameterRequestJsonResponse({},"/user/status").then((res) => {
          if(res.status == 'success' && res.results && 'logged_in' in res.results && res.results.logged_in==true)
          {
            dispatch('initializeServer').then((initialized)=>{
              if(initialized){
                commit('setLoggedIn',true);
                resolve(true);
              }
              else{
                commit('setLoggedIn',false);
                localStorage.removeItem('savedToken');
                resolve(false)
              }
              //? this is how we can call another function 
             })
          } 
          else{
            localStorage.removeItem('savedToken');
            resolve(false);
            reject('Error: Auth token invalid');
          }
        })
      });
    },

    async resetPassword({commit},resetData){
      return new Promise((resolve,reject) => {
        commit
        RequestJsonResponse(resetData,'/user/reset_password',"POST").then((res) => {
          if(res.status != 'success')
          {
            console.error(parseErrorReturn('Error: Unable to reset password',res))
            reject(parseErrorReturn('Error: Unable to reset password',res))
          }
          resolve(true)
        })
        .catch((err)=>{
          console.error('Error: Unable to reset password',err)
          resolve(false)
        });
      })
    },

    async sendForgotPasswordEmail(_,email){
      RequestJsonResponse(email,"/user/forgot_password")
    },

    async showLoggedOutMessage({commit},show)
    {
      commit('setShowLoginError',show)
    },

    async logUserOut({commit,dispatch})
    {
      RequestJsonResponse({},"/logout")
      commit("setLoggedIn",false);
      commit('reset');
      commit('setLoggingOut',true)
      if(dispatch('isLocalStorageAvailable'))
      {
        localStorage.removeItem('savedToken');
      }
      this.state.routerHistory[this.state.routerHistory.length-1]='/';
      router.replace({path:'/'})
    },

    toggleDarkMode({ commit,dispatch }) {
      commit('toggleDarkMode')
      let prefUpdate={
        'dark_mode':this.state.darkMode
      }
      dispatch('updateUserPreferences',prefUpdate);
    },

    setDarkMode({ commit }, value) {
      commit('setDarkMode', value)
    }
  },
});

function parseErrorReturn(start,err){
  let strError=start
  let found=false;
  if(err.message)
  {
    strError+=" With the message: "+err.message;
    found=true;
  }
  if(err.results && err.results.errorMessage)
  {
    strError+=" and the specifics: "+err.results.errorMessage;
    found=true;
  }
  if(!found)
  {
    strError+=" With the message: "+err;
  }
  console.error('parsing an error with the message',strError)
  return strError;
}

export  { store };
