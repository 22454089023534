/* eslint-disable */
import { defineComponent as n, openBlock as o, createElementBlock as l, renderSlot as a } from "vue";
const d = n({
  name: "VueTurnstile",
  emits: ["rendering", "rendered", "verified"],
  props: {
    siteKey: {
      type: String,
      required: !0
    },
    theme: {
      type: String,
      required: !1,
      default: "auto"
    },
    size: {
      type: String,
      required: !1,
      default: "normal"
    },
    autoReset: {
      type: Boolean,
      required: !1,
      default: !1
    },
    resetTimeout: {
      type: Number,
      required: !1,
      default: 295 * 1e3
    },
    recaptchaCompat: {
      type: Boolean,
      required: !1,
      default: !1
    },
    explicitRender: {
      type: Boolean,
      required: !1,
      default: !1
    },
    appearance: {
      type: String,
      required: !1,
      default: "always"
    },
    language: {
      type: String,
      required: !1,
      default: "auto"
    }
  },
  data() {
    return {
      widgetId: null,
      tokenResolver: null,
      isTurnstileLoaded: !1
    };
  },
  methods: {
    init() {
      if (document.querySelector("script[src*='turnstile/v0/api.js']")) return;
      const e = document.createElement("script"), t = "https://challenges.cloudflare.com/turnstile/v0/api.js";
      e.src = `${t}?onload=onloadTurnstileCallback&render=explicit`, e.async = !0, e.defer = !0, window.onloadTurnstileCallback = () => {
        this.isTurnstileLoaded = !0, this.onLoadTurnstile();
      }, document.head.appendChild(e);
    },
    reset() {
      window.turnstile && window.turnstile.reset(this.widgetId);
    },
    remove() {
      this.widgetId && (window.turnstile.remove(this.widgetId), this.widgetId = null);
    },
    render() {
      window != null && window.turnstile ? this.onLoadTurnstile() : (this.init(), window.onloadTurnstileCallback = this.onLoadTurnstile);
    },
    onLoadTurnstile() {
      this.widgetId && this.remove(), this.$emit("rendering"), this.widgetId = window.turnstile.render("#cf-turnstile", {
        sitekey: this.siteKey,
        theme: this.theme,
        size: this.size,
        appearance: this.appearance,
        language: this.language,
        callback: (e) => {
          this.$emit("verified", e), this.tokenResolver && (this.tokenResolver(e), this.tokenResolver = null), this.remove(), this.autoReset && setTimeout(() => {
            this.reset();
          }, this.resetTimeout);
        },
        "expired-callback": () => {
          this.reset();
        },
        "error-callback": (e) => {
          console.error(`Error callback: ${e}`);
        }
      }), this.$emit("rendered");
    },
    async getToken() {
      return this.widgetId || this.render(), new Promise((e) => {
        this.tokenResolver = e;
      });
    }
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    this.render();
  },
  beforeUnmount() {
    this.remove();
  },
  watch: {
    isTurnstileLoaded(e) {
      e && this.onLoadTurnstile();
    }
  }
}), u = (e, t) => {
  const i = e.__vccOpts || e;
  for (const [r, s] of t)
    i[r] = s;
  return i;
}, c = { id: "cf-turnstile" };
function h(e, t, i, r, s, f) {
  return o(), l("div", c, [
    a(e.$slots, "default")
  ]);
}
const m = /* @__PURE__ */ u(d, [["render", h]]);
export {
  m as default
};
