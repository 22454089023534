<template>
    <div class="checkbox-item" >
        <label for="checkbox" >{{field.label}}</label>
      <input type="checkbox" id="checkbox" 
        @click="updateThings"
         :class="[ darkMode ? 'dark-check-input' : 'check-input']" 
         v-model="checkbox_value" 
         :placeholder="field.placeholder||'Chat With SWIFT AI'" />
      <!-- <textarea v-if=" 'type' in field && field['type']=='str' " v-on:keydown.enter="checkKeyPressSubmit" ref="projectQuery" spellcheck="true" :class="[ darkMode ? 'dark-query-input' : 'query-input', 'project-input']" v-model="text_input"  required></textarea> -->
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
//   import Spinner_2 from "./Spinner_2.vue";
  export default {
    name: "CheckboxFormField",
    components: {
    //   Spinner_2,
    },
    props:{
        field:Object,
        field_name:String,
        darkMode:Boolean,
        sendIconDisabled:Boolean
    },

    data() {
      return {
        checkbox_value:null,
      };
    },
    mounted(){
        if ('value' in this.field){
            this.checkbox_value=this.field.value
    }
},
    computed: {
      ...mapState({
      }),
    },

    methods: {
    submitRequest() {

    },
    updateThings(){
        this.checkbox_value=!this.checkbox_value
        this.$emit('updateField',this.checkbox_value)
    },
    },
  };
  </script>
  
  <style  lang="scss"  >
  @use "../../assets/styles/main.scss" ;
  </style>
  