<template>
    <div class="modal-backdrop">
      <div :class="['modal', 'modal--groups-modal', darkMode ? 'darkModeTheme' : '']">
        <header class="modal-header">
          <h2>{{errorTitle}}</h2>
        </header>
  
        <div class="modal-body">
          <div class="error-body">
          {{errorBody}}
          </div>
        </div>
  
        <footer class="modal-footer">
          <!-- <button type="button" class="btn-apply" @click="apply">
            Apply Selection
          </button> -->
          <button
            type="button"
            class="btn-apply"
            @click="close();">
            Ok
          </button>
        </footer>
      </div>
    </div>
  </template>
  
  <script>
  // import { mapState } from "vuex";
  
  import {mapState} from "vuex";

  export default {
    name: "ErrorMessageModal",
    props:['errorTitle','errorBody'],
    components: {
    },
  
    mounted(){
    },
    data() {
      return {
      };
    },
    computed: {
      ...mapState({
        darkMode:(state)=>state.darkMode,
      }),
    },
    methods: {
      close() {
        this.$emit("close");
      },
    },
  };
  </script>
  
  <style scoped lang="scss"  >
  @use "../styles/BasicModal.scss" ;
  @use "../styles/Buttons.scss" ;
  @use "../styles/ErrorMessage.scss" ;
  </style>
  