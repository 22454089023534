<template>
    <div>
      <Doughnut ref="chartRef" :data="chartData" :options="chartOptions"/>
    </div>
  </template>
  
  <script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend)
  export default { 
    name:"DoughnutChart",
    components: {
        Doughnut  
    },
    props: {
        chartInfo:Object,
        refreshTime:Number,
        additionalPlugins:Object,
        commonChartOptions:Object
    },
    data() {
      return {
        chartData:{
            labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
            datasets: [
                {
                backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                data: [40, 20, 80, 10]
                }
            ]
        },
        chartOptions:{
            responsive: true,
            maintainAspectRatio: false,
            plugins:{
              ...this.additionalPlugins,
              title:{
                display:this.chartInfo?.name ? true:false,
                text:this.chartInfo.name,
              },
            }
        }
      };
    },
    mounted(){
    },
    computed: {

    },
    watch: {
     
    },
    methods: {
     
    }
  };
  </script>
  
  <style  lang="scss"  >
  @use "../../assets/styles/main.scss" ;
  </style>
  