<template>
    <div class="options-popup" :class="darkMode ? 'dark-options-popup' : ''" ref="options-popup" >
      <div v-if="expanded" v-closeable="{exclude:['options-popup'],handler:'closeFromClick'}">
        <Transition name="modelOptions">
        <div v-if="expanded" class="options-box">
          <div class="options-exit">
            <font-awesome-icon class="options-icon" :class="optionsChanged ? '' : 'options-reset-icon-disabled'" :icon="['fas', 'undo']" @click="resetOptions" />
            <font-awesome-icon class="options-exit-icon options-icon" :icon="['fas', 'x']" @click="expanded = false" />
          </div>
          <div class="slider-section">
          <div class="selection-option">
            <label for="creativity-slider">Creativity:</label>
            <input type="range" id="creativity-slider" name="creativity-slider" step="0.001" min="0.001"  max="2.0" v-model="creativitySlider" @mouseup="updateValue" />
          </div>
          <div class="selection-option">
            <label for="length-slider">Length:</label>
            <input type="range" id="length-slider" name="length-slider" min="50" max="2050" step="50" v-model="lengthSlider" @mouseup="updateValue" />
          </div>
          </div>
          <!-- <button
          type="button"
          class="btn-apply"
          @click="submitFeedback()">
          Ok
        </button> -->
      </div>
    </Transition>
      </div>

    <button class="options-button" v-tooltip="'Model Options'" :class="expanded ? 'button-expanded' : ''" @click='expanded =!expanded'> <font-awesome-icon :icon="['fas', 'sliders-h']" /></button>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import {closeableDirective} from '../../composables/ClickOutside.js';

//   import Spinner_2 from "./Spinner_2.vue";
  export default {
    name: "ModelOptionsFormField",
    components: {
    //   Spinner_2,
    },
    directives: {
      closeable: closeableDirective
    },
    props:{
        field:Object,
        field_name:String,
        darkMode:Boolean,
    },

    data() {
      return {
        expanded:false,
        lengthSlider:500,
        creativitySlider:0.1,
        defaultLength:500,
        defaultCreativity:0.1
      };
    },
    watch:{
      
    },
      mounted() {
        this.updateValue();
  },

    computed: {
      ...mapState({
      }),
      optionsChanged(){
        if(this.lengthSlider != this.defaultLength || this.creativitySlider != this.defaultCreativity){
          return true;
        }
        return false;
      }
    },
    methods: {
      resetOptions(){
        if(this.optionsChanged){
          this.lengthSlider = this.defaultLength;
          this.creativitySlider = this.defaultCreativity;
          this.updateValue();
        }
      },
      updateValue(){
        let updatedValue={
          creativity: parseFloat(this.creativitySlider),
          length: parseInt(this.lengthSlider),
        }
        this.$emit('updateField',updatedValue)
      },
      closeFromClick() {
        this.expanded = false; 
      }
    },

  };
  </script>
  
  <style scoped lang="scss"  >
  @use "../../assets/styles/main.scss" ;

  .dark-options-popup{
    .options-box{
      background-color: #8D8D8D;
      color: white;
    }
    .options-reset-icon-disabled{
      color: darkgrey;
    }
    .options-button{
      background-color: #333333;
      color: white;
    }
    .button-expanded{
      background-color: grey;
    }
  }

  .options-box {
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    bottom: 65px;
    right: 60px;
    width: 200px; /* Adjust width as needed */
    // padding: 10px;
    padding:2px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 0.5rem;
    // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    textarea{
        border: none;
        border-bottom: 0.5px solid #ccc;
        padding: 2px;
        width:98% !important;
        height:100%;
        resize: none;
            /*width: calc(100% - 20px);*/
            /*border: none;*/
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
            /*max-height: 85px;*/
        font-size: 1rem;
            /*height: 20px;*/ /* Initial height */
        overflow: auto; /* Hide textarea scrollbar */
    }
    textarea:focus {
        outline: none; /* Removes the default focus outline */
      }
}

.options-button{
  font-size: 1.5em;
  border-radius: 0.5rem;
  padding: 4px 7px;
  background-color: #87adf5;
  border:none;
  color: rgb(14,89,246);
}

.button-expanded{
  background-color: darkgray;
}

.options-exit{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
}
.options-exit-icon:hover {
  cursor: pointer;
}

.options-icon{
  font-size: small;
}
.options-icon:hover{
  cursor: pointer;
}

.options-reset-icon-disabled{
  color: grey;
  cursor: auto !important;
}

 .slider-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.selection-option{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding:0;
  input{
    padding:0;
    margin:0;
  }
  label{
    font-size: small;
  }
}

  </style>
  