import "core-js/stable";
import   { createApp } from "vue";
import App from "./App.vue";
import { store } from "./store/Store";
import router from "./util/Routes";
// import VueCookies from "vue-cookies";
// import JsonExcel from "vue-json-excel";
import vue3GoogleLogin from 'vue3-google-login'
import { CustomTooltip } from "./composables/CustomTooltip";
import { closeableDirective } from "./composables/ClickOutside";
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'

import './assets/styles/main.scss'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { GDialog } from 'gitart-vue-dialog';
import 'gitart-vue-dialog/dist/style.css';

// Create a new Vue instance as an event bus

// Make the event bus accessible globally
// You can attach it to the window object

library.add(fas);

const app=createApp(App)
.use(store)
.use(router)
.use(vue3GoogleLogin,{
  clientId:'1083079375389-oarkhggg43ch4cags3u8ce6mpr0pdmo4.apps.googleusercontent.com'
})
.directive('tooltip',CustomTooltip)
.directive('closeable',closeableDirective)
.component('font-awesome-icon',FontAwesomeIcon)
.component('GDialog', GDialog)
.mount('#app');



router.beforeEach((to, from, next) => {
  const vueInstance = app.$root;
  if(to.name === 'Sign In' && from.path=='/')
  {
    resetApp();
    next();
    vueInstance.routeHistory.push(to.path);
  }
  else if (to.name==='Sign In' && from.path!='/')
  {
    if(store.state.loggingOut==true || from.path=='/reset_password')
    {
      resetApp();
      next();
      vueInstance.routeHistory.push(to.path);
    }
    else{
      openLogoutPromptModal();
      next(false);
  }
  }
  else if(to.name=='Reset Password')
  {
    resetApp();
    next();
    vueInstance.routeHistory.push(to.path);
  }
  else{
    if(!vueInstance.loggedIn)
    {
      resetApp();
      store.dispatch('logUserOut')
    }
    else{
      vueInstance.routeHistory.push(to.path);
      next();
    }
  }
});


function resetApp()
{
  store.dispatch('resetState');
}


function openLogoutPromptModal() {
    const vueInstance = app.$root;
    vueInstance.showLogout()
}