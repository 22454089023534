<template>
  <div class="modelResponseChat">
    <div id="modelResponse" >  
        <div class="response-icon">
            <!-- <font-awesome-icon :icon="['fas', 'user']" /> -->  
            <span class="star-icon">&#9733;</span>
        </div>
        <div class="information-section" ref="informationSection">
        <div v-if="responseData.success" >
          <span v-if="!responseIsHtml" :class="darkMode ? 'dark-text-area' : 'text-area'" >{{formatResponse(responseData.response)}}</span>
          <div class="html-container"  v-else v-html="formatHtml(responseData.response)" ></div>

          <div v-for="custom in responseData.custom_response" :key="custom.custom_response_id" ref='customComponents'>
            <component class="custom-component" :is="getCustomComponent(custom)"  :darkMode="darkMode" :componentData="custom" :ref="custom.custom_response_id" />
          </div>
          <br>
          <div class="options-section">
          <font-awesome-icon :class="darkMode ? 'dark-icon' : 'icon'" v-tooltip="copyStatus" v-if="!responseIsHtml"  @click="copyOutput" :icon="copyIcon" />
          <!-- <font-awesome-icon :class="darkMode ? 'dark-icon' : 'icon'" v-tooltip="'Send Email'" @click="createEmailLink" v-if="responseData.queryType==0" :icon="emailIcon" /> -->
            <UserFeedback :initialFeedback="responseData.feedback" @sendFeedback="sendResponseRating" />
          <!-- <font-awesome-icon class="icon" v-tooltip="copyStatus"  @click="copyOutput" :icon="copyIcon" /> -->
          </div>
        </div>
        <div v-if="!responseData.success">
          
            <span :class="darkMode ? 'dark-text-area' : 'text-area'" ><b>Error: </b>{{formatError(formatResponse(responseData.response))}}</span>
        </div>
        </div>
        <div class="loading-spinner" v-if="loading"><Spinner_2/></div>
        </div>
  </div>
    
  </template>
  
  <script>
  import { mapState } from "vuex";
  import Spinner_2 from "./Spinner_2.vue";
  import GraphComponent from "./output_display_components/GraphComponent.vue";
  import SourceComponent from "./output_display_components/SourceComponent.vue";
  import UserFeedback from "./UserFeedback.vue";
  export default {
    name: "ModelResponse",
    components: { 
      Spinner_2,
      GraphComponent,
      SourceComponent,
      UserFeedback,
    },
    props:{
        responseData:Object
    },
    data() {
      return {
        loading:false,
        copyStatus: "Copy",
        defaultCopyIcon: ['fas','copy'],
        defaultEmailIcon: ['fas','envelope'],
        checkmarkIcon:['fas','check'],
        copyIcon:['fas','copy'],
        emailIcon:['fas','envelope'],
        errorIcon:['fas','times'],
      };
    },

    mounted(){
    },
    computed: {
      ...mapState({
        darkMode:(state)=>state.darkMode,
      }),
      getCustomComponent(){
        return (custom)=>{
          switch(custom.response_type){
            case 'graph':
              return 'GraphComponent'  
            case 'sources':
              return 'SourceComponent'
            default:
                return 'none'
          }
          // return 'textarea'
        }
      },
      isSelected(){
        if(this.selected && this.selected.record_id && this.selected.record_id==this.requestData.record_id)
        {
            return true;
        }
        return false;
      },


      responseIsHtml()
      {
        if(this.responseData && this.responseData.response) {
        //  if(( this.response_parsed && typeof this.response_parsed === 'object' && 'html_response' in this.response_parsed || this.response_parsed.includes("<html>")||this.response_parsed.includes("</svg>")))
        if(this.responseData && this.responseData.response && ( this.responseData.response.includes("<html>")||this.responseData.response.includes("</svg>")))
        {
          return true
        }
      }
        return false
      },
    },
    watch:{

    },
    methods: {


      sendResponseRating(feedbackObj){
        let record_id=null
        if( this.responseData.queryData && this.responseData.queryData.record_id)
        {
          record_id=this.responseData.queryData.record_id;
        }
        else if(this.responseData.record_id)
        {
          record_id=this.responseData.record_id
        }
        if(record_id!=null && (feedbackObj.positive !=null || feedbackObj.responseFeedback!=""))
        {
          feedbackObj.model_request_id=record_id
          this.$store.dispatch("responseFeedback",feedbackObj).then((result)=>{
              if(result.status=='success')  
              {
                return;
              }
            else if( result.message){
                console.error("Feedback Error: "+result.message)
              }
              else{
                console.error('Feedback Error: Could not update ')
              }
            }).catch((err)=>{
              if(err!="")
              {
                this.loginMsgStr=err;
                console.error('Feedback error: ',err)
              }
              else{
                console.error('Feedback Error: Could not update ')
              }
            });
        }
        else{
          console.warn('We have nothing to update')
        }
      },

      async copyOutput(){
        try{
          let clipboardText=this.formatResponse(this.responseData.response);
          let clipboardPromises=[]
          // let copyText=this.formatResponse(this.responseData.response)?

          if (this.responseData && this.responseData.custom_response && this.responseData.custom_response.length>0){
          this.responseData.custom_response.forEach((custom)=>{
            let foundRef= this.$refs[custom.custom_response_id]
            if( foundRef.length>0 && 'copyComponent' in foundRef[0])
            {
                clipboardPromises.push(foundRef[0].copyComponent())
            }
          })
        }
        const items=await Promise.all(clipboardPromises)
        let finalText=clipboardText+items.join('');
          await navigator.clipboard.write([new ClipboardItem({ 'text/plain': new Blob([finalText], { type: 'text/plain' }) })]);
          this.copyStatus="Copied";
          this.copyIcon=this.checkmarkIcon;
        }
        catch($e){
          console.error('Cannot copy with the error',$e)
          this.copyStatus='Error: unable to copy'
          this.copyIcon=this.errorIcon;
        }
        finally{
          this.resetIconsAndStatus();
        }
      },

      resetIconsAndStatus(){
        setTimeout(()=>{  
            this.copyStatus="Copy"
            this.copyIcon=this.defaultCopyIcon;
            this.emailIcon=this.defaultEmailIcon
          },1000)
      },

      createEmailLink(){
        try{
        var encodedSubject = this.responseData.queryData && this.responseData.queryData.project?encodeURIComponent(this.responseData.queryData.project):'';
        var encodedBody = encodeURIComponent(this.formatResponse(this.responseData.response));

        var mailToUrl = 'mailto:?subject=' + encodedSubject + '&body=' + encodedBody;
        this.emailIcon=this.checkmarkIcon;
        window.open(mailToUrl);
        }
        catch(e)
        {
          console.error('We had an error while launching the email link',e)
          this.emailIcon=this.errorIcon;
        }
        finally{
          this.resetIconsAndStatus();
        }
      },
      formatResponse(textToFormat)
      {
        try{  
          return textToFormat.replace('f"""', "").replace('"""','').trim(); //Just used tp replace the tri
        }catch(e){
          console.error('cannot format the text',e)
          return textToFormat
        }
      },
      formatHtml(textToFormat){
        try{
          return textToFormat.replace(/\\n/g,'').replace(/\\(?![\n\r\t])/g, '').replace(/^"|"$/g, '');
        }
        catch(e){
          console.warn('Cannot stringify the html',e)
          return textToFormat; 
        }
      },
      formatError(textToFormat)
      {
        return textToFormat.replace(/^Error:\s*/, '');
      }
    },
  };
  </script>
  
  <style  lang="scss"  >
  @use "../assets/styles/main.scss" ;
  @use "./styles/ChatEntry.scss" ;
  @use "./styles/Buttons.scss" ;
  </style>
  