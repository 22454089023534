/**
 * This file is used to authenticate login information in Login.vue
 *
 * Currently emailIsValid function is not being used
 */

export default {
    methods: {
      nameIsValid(name) {
        const re = /^[a-zA-Z0-9 '-]*$/;
        return re.test(name);
      },
      emailIsValid(email) {
        const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,}(?:\.[a-z]{2})?)$/i;
        return re.test(email);
      },
      passwordIsValid(password) {
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        // const specialCharacterRegex = /[!@#$%^&*()_+{}:;<>,.?]/;
        const specialCharacterRegex = /[!@#$%^&*()_+{}:;<>,.?0-9]/;
  
          return password.length >= 6 &&
          lowercaseRegex.test(password) &&
          uppercaseRegex.test(password) &&
          specialCharacterRegex.test(password);
        }    },
  };
  