<template>
    <div id="conversationEntry">  
        <UserRequest 
        :requestData="getUserData()"
        />           
        <ModelResponse v-if="conversationObject.response || ( 'custom_response' in conversationObject && conversationObject.custom_response.length>0)"
        :responseData="getResponseData()"
        />      
        </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  // import Spinner_2 from "./Spinner_2.vue";
  import UserRequest from "./UserRequest.vue";
  import ModelResponse from "./ModelResponse.vue";
  export default {
    name: "ConversationEntry",
    components: {
      // Spinner_2,
      UserRequest,
      ModelResponse
    },
    props:{
        conversationObject:Object
    },

    data() {
      return {
        loading:false,
      };
    },
    watch: {
      
    },

    mounted(){
    },
    computed: {
      ...mapState({
      }),
 
      isSelected(){
        if(this.selected && this.selected.record_id && this.selected.record_id==this.requestData.record_id)
        {
            return true;
        }
        return false;
      },
    },
    methods: {
      getUserData(){
        return {
          userQuery: this.conversationObject.user_query,
          title: this.conversationObject.title,
          queryData: this.conversationObject.query_data,
          // title: this.conversationObject.user_query,
          record_id: this.conversationObject.record_id,
          displayText: this.conversationObject.textToDisplay??null
        }
      },
      getResponseData(){
        return {
          feedback: this.conversationObject.feedback,
          response: this.conversationObject.response,
          queryData: this.conversationObject.query_data,
          success:true,
          record_id:this.conversationObject.record_id,
          custom_response: this.conversationObject.custom_response,
        }
      }
    },
  };
  </script>
  
  <style  lang="scss"  >
  @use "../assets/styles/main.scss" ;
  @use "./styles/ChatEntry.scss" ;
  </style>
  